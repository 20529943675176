import React, { useEffect, useState } from "react";
import { MdOutlineIosShare } from 'react-icons/md';
import { useStoreContext } from '../../../utils/GlobalState';

const Android = (props) => {
  const setIsMobile = props.setIsMobile;

  const [state, dispatch] = useStoreContext();

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = async(evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    await promptInstall.prompt();
    const { outcome } = await promptInstall.userChoice;
    if (outcome === 'accepted') {
      setPromptInstall(null);
  }
    
  };
  if (!supportsPWA) {
    return null;
  }
  
  return (
    <div className="absolute h-32 top-0 w-full noMargin flex text-white" style={{ backgroundColor: state?.guide?.colorTwo }}>
      <button onClick={() => setIsMobile(false)} className="w-8 h-8 pt-1 rounded-md font-bold text-center text-xl my-auto h-full w-12">X</button>
      <p className="w-1/2 mx-auto text-left my-auto">Save Guide to your Home Screen for easier access</p>
      <button onClick={onClick} className=" h-12 my-auto px-6 rounded-xl ml-auto mr-4" style={{ backgroundColor: state?.guide?.colorOne }}>Save</button>
    </div>
  )
}

export default Android;