import React, { useState, useRef, useEffect } from "react";
import RenderModal from "./modal";
import AddSection from "./addSection";
import AddSubCategory from "./addSubCategory";
import EditSubCategory from "./editSubCategory";
import { useMutation } from "@apollo/client";
// eslint-disable-next-line 
import { AiOutlineCar } from 'react-icons/ai'
import { BsThreeDotsVertical, BsPlusCircle, BsWifi, BsHouse, BsKey, BsTelephone, BsBagCheck, BsStar, BsPinMap, BsController, BsBook, BsHeart, BsEmojiSmile, BsCart3, BsCurrencyDollar, BsPower, BsListUl, BsMap, BsSignpostSplit, BsClock } from 'react-icons/bs';
import { FiSettings, FiTrash2, FiEye } from 'react-icons/fi';
import { BiBed, BiPlusMedical } from 'react-icons/bi';
import { GiBinoculars, GiForkKnifeSpoon, GiNightSleep, GiAirplaneDeparture } from 'react-icons/gi';
import { MdPets, MdOutlineWarningAmber } from 'react-icons/md';
// eslint-disable-next-line 
import { IoIosArrowDown, IoHandRightOutline, IoFastFoodOutline, IoCarSportOutline } from 'react-icons/io';
import { DELETE_CATEGORY, DELETE_SUBCATEGORY, CHANGE_CATEGORY_ORDER, UPDATE_CATEGORY_ORDER } from '../../../utils/mutations';
import { useStoreContext } from "../../../utils/GlobalState";
import info from '../../../assets/info.png';
import catPic from "../../../assets/category.png";

const EditSection = (guideId) => {
  const [state] = useStoreContext();

      //DISPLAY ICON ON SECITON
      const displayIcon = (icon) => {
        switch (icon) {
          case 'BsWifi':
            return (
              <div className="flex justify-around">
                <BsWifi className="text-3xl" /> 
              </div>
            )
          case 'BsHouse':
            return (
              <div className="flex justify-around">
                <BsHouse className="text-3xl" /> 
              </div>
            )
          case 'BsKey':
            return (
              <div className="flex justify-around">
                <BsKey className="text-3xl" /> 
              </div>
            )
          case 'BsTelephone':
            return (
              <div className="flex justify-around">
                <BsTelephone className="text-3xl" /> 
              </div>
            )
          case 'AiOutlineCar':
            return (
              <div className="flex justify-around">
                <AiOutlineCar className="text-3xl" /> 
              </div>
            )
          case 'BsBagCheck':
            return (
              <div className="flex justify-around">
                <BsBagCheck className="text-3xl" /> 
              </div>
            )
          case 'BsStar':
            return (
              <div className="flex justify-around">
                <BsStar className="text-3xl" /> 
              </div>
            )
          case 'BsPinMap':
            return (
              <div className="flex justify-around">
                <BsPinMap className="text-3xl" /> 
              </div>
            )
          case 'BsController':
            return (
              <div className="flex justify-around">
                <BsController className="text-3xl" /> 
              </div>
            )
          case 'BsBook':
            return (
              <div className="flex justify-around">
                <BsBook className="text-3xl" /> 
              </div>
            )
          case 'BsHeart':
            return (
              <div className="flex justify-around">
                <BsHeart className="text-3xl" /> 
              </div>
            )
          case 'BsEmojiSmile':
            return (
              <div className="flex justify-around">
                <BsEmojiSmile className="text-3xl" /> 
              </div>
            )
          case 'BsCart3':
            return (
              <div className="flex justify-around">
                <BsCart3 className="text-3xl" /> 
              </div>
            )
          case 'BsCurrencyDollar':
            return (
              <div className="flex justify-around">
                <BsCurrencyDollar className="text-3xl" /> 
              </div>
            )
          case 'BsPower':
            return (
              <div className="flex justify-around">
                <BsPower className="text-3xl" /> 
              </div>
            )
          case 'BsListUl':
            return (
              <div className="flex justify-around">
                <BsListUl className="text-3xl" /> 
              </div>
            )
          case 'BsMap':
            return (
              <div className="flex justify-around">
                <BsMap className="text-3xl" /> 
              </div>
            )
          case 'BsSignpostSplit':
            return (
              <div className="flex justify-around">
                <BsSignpostSplit className="text-3xl" /> 
              </div>
            )
          case 'FiSettings':
            return (
              <div className="flex justify-around">
                <FiSettings className="text-3xl" /> 
              </div>
            )
          case 'FiTrash2':
            return (
              <div className="flex justify-around">
                <FiTrash2 className="text-3xl" /> 
              </div>
            )
          case 'FiEye':
            return (
              <div className="flex justify-around">
                <FiEye className="text-3xl" /> 
              </div>
            )
          case 'BiBed':
            return (
              <div className="flex justify-around">
                <BiBed className="text-3xl" /> 
              </div>
            )
          case 'BiPlusMedical':
            return (
              <div className="flex justify-around">
                <BiPlusMedical className="text-3xl" /> 
              </div>
            )
          case 'GiBinoculars':
            return (
              <div className="flex justify-around">
                <GiBinoculars className="text-3xl" /> 
              </div>
            )
          case 'GiForkKnifeSpoon':
            return (
              <div className="flex justify-around">
                <GiForkKnifeSpoon className="text-3xl" /> 
              </div>
            )
          case 'GiNightSleep':
            return (
              <div className="flex justify-around">
                <GiNightSleep className="text-3xl" /> 
              </div>
            )
          case 'GiAirplaneDeparture':
            return (
              <div className="flex justify-around">
                <GiAirplaneDeparture className="text-3xl" /> 
              </div>
            )
          case 'MdPets':
            return (
              <div className="flex justify-around">
                <MdPets className="text-3xl" /> 
              </div>
            )
          case 'MdOutlineWarningAmber':
            return (
              <div className="flex justify-around">
                <MdOutlineWarningAmber className="text-3xl" /> 
              </div>
            )
          case 'BsClock':
            return (
              <div className="flex justify-around">
                <BsClock className="text-3xl" /> 
              </div>
            )
          default:
            break;
        }
      }

      //  TOGGLE ADD SECTION FUNCTIONALITY
      const [activeAddSection, setActiveAddSection] = useState(false);

      const  showAddSection = (category) => {
        setActiveAddSection(true);
      };
  
      //  TOGGLE EDIT SECTION FUNCTIONALITY
      const [activeModal, setActiveModal] = useState(false);
      const [currentCategory, setCurrentCategory] = useState();
  
      const  showModal = (category) => {
        setCurrentCategory(category);
        setActiveModal(true);
      };

      // TOGGLE EDIT SUBCATEGORY FUNCTIONALITY
      const [ActiveEditSubCategory, setActiveEditSubCategory] = useState(false);
      const [subCat, setSubCat] = useState();
      const [cat, setCat] = useState();

      const handleActiveEditSub = (subCategory, category) => {
        setSubCat(subCategory);
        setCat(category)
        setActiveEditSubCategory(true);
      }

      //change the order of categories
      const [changeCategoryOrder] = useMutation(CHANGE_CATEGORY_ORDER);
      const handleChageOrder = async() => {
        console.log(guideId)
        try {
          const changedCategory = await changeCategoryOrder({
            variables: {
              guideId: guideId.guideId
            }
          })
          console.log(changedCategory.data.changeCategoryOrder)
        }
        catch (e){
          console.log(e);
        }
      }
  
      //delete Category functionality
      const [deleteCategory] = useMutation(DELETE_CATEGORY);
  
      const handleDeleteCategory = async(categoryId, guideId) => {
        try {
          await deleteCategory({
            variables: {
              guideId: guideId,
              categoryId: categoryId
            }
          })
        } catch (e) {
          console.log(e);
        }
      }

      //Expand Category
      const categoryDropDown = async(e) => {
        const Section = e.currentTarget.parentNode.parentNode;
        const button = e.currentTarget.children[1];
        if(button.classList.contains('rotateButtonDown')){
          button.classList.remove('rotateButtonDown');
          button.classList.add('rotateButtonUp');
        } else {
          button.classList.remove('rotateButtonUp');
          button.classList.add('rotateButtonDown');
        }
        
        if(Section.classList.contains('categorySectionClosed')){
          Section.classList.remove('categorySectionClosed');
          Section.classList.add('categorySectionOpen');
        } else {
          Section.classList.remove('categorySectionOpen');
          Section.classList.add('categorySectionClosed');
        }
      }

      //open and close categoryNav
      const inCategoryNav = useRef();
      // const [activeCategoryNav, setActiveCategoryNav] = useState('false');
      const clickCirlces = (e) => {
        // console.log(e.currentTarget.parentNode.parentNode.children[1]);
        const nav = e.currentTarget.parentNode.parentNode.children[1]
        if(nav.classList.contains('categoryNavClosed')){
          nav.classList.add('categoryNavOpen');
          nav.classList.remove('categoryNavClosed');
        } else {
          nav.classList.add('categoryNavClosed');
          nav.classList.remove('categoryNavOpen');
        }
      }

      //open and close subCategory dropdown
      const subDropDown = (e) => {
        console.log(e.target.classList.contains('3dot'));
        if(e.target.classList.contains('3dot')){
          return;
        }
        const button = e.currentTarget.parentNode.children[0].children[2].children[0];
        if(button.classList.contains('rotateSubButtonDown')){
          button.classList.remove('rotateSubButtonDown');
          button.classList.add('rotateSubButtonUp');
        } else {
          button.classList.remove('rotateSubButtonUp');
          button.classList.add('rotateSubButtonDown');
        }
        // console.log()

        // const subCategorySection = e.currentTarget.parentNode.parentNode.parentNode;
        const subCategorySection = e.currentTarget.parentNode
        if(subCategorySection.classList.contains('subCategoryClosed')){
          subCategorySection.classList.remove('subCategoryClosed');
          subCategorySection.classList.add('subCategoryOpen');
        } else {
          subCategorySection.classList.remove('subCategoryOpen');
          subCategorySection.classList.add('subCategoryClosed');
        }
      }
  
      // display add sub category modal
      const [activeAddSubCategory, setActiveAddSubCategory] = useState(false);
      const [categoryIndex, setCategoryIndex] = useState();

      const showAddSubCat = (category, guideId, index) => {
        state?.guide?.categories?.forEach((cat, index) => {
          if(cat._id === category._id){
            setCategoryIndex(index)
          }
        });
        // setCategoryIndex(index);
        setCurrentCategory(category);
        setActiveAddSubCategory(true);
      };

      //openSubCat menu
      const openSubCatMenu = (e) => {
        const SubCategory = e.currentTarget.parentNode.parentNode.parentNode;

        if(SubCategory.classList.contains('subCategoryMenuClosed')){
          SubCategory.classList.add('subCategoryMenuOpen');
          SubCategory.classList.remove('subCategoryMenuClosed');
        } else{
          SubCategory.classList.add('subCategoryMenuClosed');
          SubCategory.classList.remove('subCategoryMenuOpen');
        }
      }

      // delete subCategory
      const [deleteSubCategory] = useMutation(DELETE_SUBCATEGORY);
      const handleDeleteSubCategory = async(e) => {
        
        const category = e.currentTarget.parentNode.parentNode.parentNode.id;
        const SubCategoryId = e.currentTarget.id;
        // console.log(category, SubCategoryId)

        try{
          await deleteSubCategory({
            variables: {
              guideId: guideId.guideId,
              categoryId: category,
              subCategoryId: SubCategoryId
            }
          });
        } catch (e){
          console.log(e);
        }
      }

  // draggable js
  
  // useEffect(() => {
    
  //   draggables.forEach(draggable => {
  //     draggable.addEventListener('click', () => {
  //       draggable.classList.add('dragging')
        
  //     })
  
  //     draggable.addEventListener('dragend', () => {
  //       draggable.classList.remove('dragging')
        
  //     })
  //   })
  // });


  const [updateCategoryOrder] = useMutation(UPDATE_CATEGORY_ORDER);
  const [activeDragging, setActiveDragging] = useState(false)
  
  let draggables = document.querySelectorAll('.draggable');

  const unOrderedCategories = state?.guide?.categories;
  let orderedCategories;
  
  let displayCategories;

  useEffect(() => {
    draggables = document.querySelectorAll('.draggable');

    //put categories in order for display
    const orderCats = async() => {
      if(unOrderedCategories[0].order){
        displayCategories = [...unOrderedCategories].sort((a, b) => a.order - b.order);
        console.log(displayCategories)
      } else {
        displayCategories = unOrderedCategories;
        console.log(displayCategories)
      }
    }
    orderCats().catch(console.error)
    
  }, []);

  const sectionContainer = document.getElementById('sectionContainer')
  const dragStart = (e) => {
    if(!activeDragging){
      console.log('cant drag yet')
      return
    }
    e.currentTarget.classList.add('dragging')
  }
  const dragEnd = (e) => {
    if(!activeDragging){
      console.log('cant drag yet')
      return
    }
    e.currentTarget.classList.remove('dragging')
  }

  const dragOver = (e) => {
    if(!activeDragging){
      console.log('cant drag yet')
      return
    }

    e.preventDefault();
    const afterElement = getDragAfterElement(sectionContainer, e.clientY);
    const draggable = document.querySelector('.dragging');
    if(afterElement == null){
      sectionContainer.appendChild(draggable)
    } else {
      sectionContainer.insertBefore(draggable, afterElement)
    }
    
  }

  const getDragAfterElement = (container, y) => {
    // console.log(container);
    const draggableElements = [...container.querySelectorAll('.draggable:not(.dragging)')]
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect();
      const offset = y - box.top - box.height / 2;
      if(offset < 0 && offset > closest.offset) {
        return{ offset: offset, element: child }
      } else {
        return closest;
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element
  }

  const subDragText = {
    'font-size': 12,
    'font-weight': 400,
  }

  const handleDragging = async (e) => {
    console.log(e.currentTarget)
    if(activeDragging == false) {
      console.log(draggables)
      draggables.forEach(draggable => {
        console.log(draggable)
        draggable.setAttribute('draggable', true);
      })
      e.currentTarget.innerHTML = `Save Re-Order Changes<br> <span>(click and drag sections to change their order)</span>`;
      setActiveDragging(true)
    } else {
      setActiveDragging(false)
      draggables.forEach(draggable => {
        draggable.setAttribute('draggable', false);
      })
      e.currentTarget.innerHTML = 'Re-Order Sections'

      //save reordered sections to database
      const sectionArr = [...sectionContainer.children]
      handleUpdateOrder(sectionArr)
    }
    
  }
  
  const handleUpdateOrder = async(sectionArr) => {
    sectionArr.forEach(async (child, index) => {
      console.log(child.id)
      try{
        await updateCategoryOrder({
          variables: {
            guideId: guideId.guideId,
            categoryId: child.id,
            order: index + 1
          }
        })
      } catch(e) {
        console.log(e)
      }
    })
  }


  // Put Sections in order for display
  
  
  console.log(displayCategories)
  return (
    <div className="md:w-full w-11/12 m-auto">
    {state?.guide?.categories?.length > 1 && (
      <button onClick={handleDragging} className="md:ml-auto md:mr-6 font-bold mt-5 border-cyan-600 border-4 p-2 px-3 pt-3 pl-4 rounded-lg shadow-lg mb-3 hover:shadow-xl"> Re-Order Sections</button>
    )}
    <button className="m-auto font-bold md:mt-5 border-cyan-600 border-4 p-2 px-3 pt-3 pl-4 rounded-lg shadow-lg md:mb-3 mb-6 hover:shadow-xl" onClick={showAddSection}>Add Section ➕</button>
  
  <div className="flex flex-wrap md:mx-5 content-around m-auto justify-center">
  {!state?.guide?.categories.length ? (
    <div className="m-auto text-xl font-bold my-6">
      <h2>Add Content to your guide here, this is the information that will fill out the 'info' section of your guide. </h2>
      <div className="flex w-fit m-auto">
      <img className="w-[160px] m-auto mr-5" src={info} />
      <img className="w-[166px] m-auto" src={catPic} />
      </div>
    </div>  
  ):(<div></div>)}
  {/* <button onClick={handleChageOrder} className='mainColor px-4 py-2 text-white font-bold'> CHANGE ORDER </button> */}
  <div  id="sectionContainer" onDragOver={dragOver} className="w-full max-w-[1300px] m-auto">
  {/* {state?.guide?.categories.map((category, index) => { */}
    { [...unOrderedCategories].sort((a, b) => a.order - b.order).map((category, index) => {
    return(
    <section onDragStart={dragStart} onDragEnd={dragEnd} id={category._id} key={category._id} draggable="false" className='draggable categorySectionClosed w-full md:w-4/5 m-auto text-white shadow-xl flex-wrap'>
      <header className="mainColor cursor-grab active:cursor-grabbing flex justify-between h-max text-3xl w-full rounded-lg py-2 h-[55px]">
        <div  onClick={categoryDropDown} className="flex justify-between w-full">
          <div className="flex mr-auto md:ml-8 pt-1 w-4/5 md:w-fit">
            <p className="font-semibold text-lg md:text-3xl md:w-fit max-w-[900px] text-left ml-2 md:ml-0 relative bottom-1 overflow-hidden text-ellipsis whitespace-nowrap">{category.name}</p>
            <p className="opacity-0 ">hi</p>
            <div className="mr-5 md:mr-0">{displayIcon(category.icon)}</div>
          </div>
          <button className="rotateButtonDown cursor-pointer">
            <IoIosArrowDown  />
          </button>
        </div>
        <button onClick={clickCirlces} className="mr-4 ml-2 cursor-pointer">
          <BsThreeDotsVertical />
        </button>
      </header>
      <nav ref={inCategoryNav} className="bg-white border-4 w-60 homeText categoryNavClosed relative z-20">
        <ul>
          <li className="categoryNavTransition">
          <button className="mx-5" onClick={() => showModal(category)}>Edit Section</button> 
          </li>
          <li className="categoryNavTransition">
            <button onClick={() => showAddSubCat(category, guideId, index)}>
              add subcategory
            </button>
          </li>
          <li className="categoryNavTransition">
          <button onClick={() => handleDeleteCategory(category._id, state?.guide?._id)}>Delete Section</button>
          </li>
        </ul>
      </nav>
      <article className="w-full bg-white text-black border-2 mt-[-90px] rounded-b-lg">
        <div className="flex flex-wrap w-4/5 m-auto">
          <h5 className="p-1 font-bold text-xl">Description:</h5>
          <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: category.description}} />
          {/* <p className=" p-1 px-2 rounded text-xl">{category.description}</p> */}
        </div>
        { category.subCategories && 
        <div id={category._id}>
          
          {category.subCategories.map((subCategory) => {
            return (
            <div key={subCategory._id} id={subCategory._id} className='subCategoryMenuClosed subCategory subCategoryClosed w-4/5 m-auto py-2 md:p-2 my-5'>
              <header onClick={subDropDown} className="cursor-pointer w-11/12 m-auto flex justify-between">
              <IoIosArrowDown className="opacity-0 sm:pr-0 md:pr-16 w-0 md:w-auto" />
                <h5 className="font-bold text-xl border-b-2">{subCategory.name}</h5>
                <div className="flex">
                  <button className="rotateSubButtonDown flex text-black">
                    <div className="my-auto mr-3">
                      <IoIosArrowDown className=" text-2xl w-6 h-full" />
                    </div>
                  </button>
                  <button onClick={openSubCatMenu} className="3dot">
                    <BsThreeDotsVertical  className="3dot w-[20px] mx-2 h-full text-2xl text-black" />
                  </button>
                  
                </div>
              </header>
              <nav className="w-60 bg-white flex flex-wrap border-2 rounded">
                <button id={subCategory._id} onClick={() => handleActiveEditSub(subCategory, category)} className="categoryNavTransition w-full text-cente  hover:shadow-xlr">Edit Sub-Category</button>
                <button id={subCategory._id} onClick={handleDeleteSubCategory} className="categoryNavTransition w-full text-center  hover:shadow-xl">Delete Sub-Category</button>
              </nav>
              <article className="w-full m-auto flex flex-wrap md:flex-nowrap">
                {subCategory.picture && (
                  <div className="w-full md:w-1/2 h-[220px] m-auto">
                    <img className="w-full h-[220px] object-cover rounded" src={subCategory.picture} />
                  </div>
                )}
                <div className="w-full md:w-1/2 p-5">
                <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: subCategory.description}} />
                {/* <p className="text-left md:px-5">{subCategory.description}</p> */}
                {subCategory.link && (
                  <a href={subCategory.link} target='_blank' className='text-blue-500 underline'>
                    {subCategory.linkName ? 
                    (
                      <p>{subCategory.linkName}</p>
                    ) : (
                      <p>{subCategory.link}</p>
                    )}
                  </a>
                )}
                </div>

              </article>
            </div>)
          })}
        </div>
          
        }
          
        
        
        <button id={category._id} onClick={() => showAddSubCat(category, guideId, index)} className="bg-gray-100 w-11/12 my-4 p-5 rounded-lg border-2 border-gray-200 flex m-auto  hover:shadow-xl">
          <BsPlusCircle className="ml-auto text-xl" /> 
          <p className="mr-auto pl-2 text-xl">Add a Sub-Category</p>
        </button>
      </article>
      
      
    </section>
    )
  })}
  </div>
  {state?.guide?.categories.length ? (
  <div className="w-full mt-16 md:mt-8">
  <button className="m-auto mt-5 border-cyan-600 border-4 font-bold p-2 px-3 pt-3 pl-4 rounded-lg shadow-lg mb-3  hover:shadow-xl" onClick={showAddSection}>Add Section ➕</button>
  </div>
  ):(<div></div>)}
  </div>
  {activeModal ? (
  <div className="flex flex-wrap absolute top-0 left-0 w-full">
    <RenderModal guideId={guideId} category={currentCategory} setActiveModal={setActiveModal} /> 
    
  </div> ) :(<div></div>)}

    {activeAddSection && (
    <div className="absolute top-0 left-0 w-full h-full">
      <AddSection guideId={state?.guide?._id} setActiveAddSection={setActiveAddSection}/>
    </div> )}

    {activeAddSubCategory && (
    <div className="absolute top-0 left-0 w-full h-full">
      <AddSubCategory guideId={state?.guide?._id} category={currentCategory} setActiveAddSubCategory={setActiveAddSubCategory} categoryIndex={categoryIndex}/>
    </div> )}

    {ActiveEditSubCategory && 
    <div className="absolute top-0 left-0 w-full h-full">
      <EditSubCategory subCat={subCat} cat={cat} setActiveEditSubCategory={setActiveEditSubCategory} guideId={state?.guide?._id} />
    </div>
    }
    </div>
  )
};

export default EditSection;