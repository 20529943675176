import anini from '../assets/anini.png';
import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_USER, LOGIN } from '../utils/mutations';
import Auth from '../utils/auth';

import homePrev from '../assets/homePrev.png';
import mapPrev from '../assets/mapPrev.png';
import infoPrev from '../assets/infoPrev.png';


function LandingPage() {

    let signUpWarn = useRef()
    // send verify email
    const [sent, setSent] = useState(false);
    const handleSendEmail = async (userData) => {
      setSent(true)
      try {
          const sentEmail = await fetch("/sendWelcome", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(userData)
        });
      } catch(e) {
        console.log(e);
      }
    }
    //SIGNUP FUNCTIONALITY
    const [signupFormState, setSignupFormState] = useState({
      uname: "",
      eml: "",
      psw: "",
      subscriptionStatus: "false"
    });
    const [addUser] = useMutation(ADD_USER);
  
    const [errorText, setErrorText] = useState('.')
  
    const handleSignupFormSubmit = async (event) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(signupFormState.eml)){
      try{
      const mutationResponse = await addUser({
        variables: {
          username: signupFormState.eml,
          email: signupFormState.eml,
          password: signupFormState.psw,
          subscriptionStatus: signupFormState.subscriptionStatus
        },
      });
      // eslint-disable-next-line
      const userData = mutationResponse?.data?.addUser?.user;
      handleSendEmail(userData);
      
      const token = mutationResponse.data.addUser.token;
      Auth.login(token);
      if(Auth.loggedIn) {
        window.location.replace("/dashboard");
      }
      } catch(e){
        console.log(e)
        let error = e.toString();
        console.log(error)
        if(error.includes('password:') && error.includes('is shorter than the minimum allowed length')){
          // signUpWarn.current.classList.add('error');
          setErrorText('Password too short , must be at least 7 characters long')
        } else if(error.includes('duplicate key error collection') && error.includes('dup key: { email:')){
          // signUpWarn.current.classList.add('error');
          setErrorText('An account already exists for this email address')
        } else if(error.includes('Path `username` is required.')){
          // signUpWarn.current.classList.add('error');
          setErrorText('Must enter a username')
        } else if(error.includes('Path `email` is required.')){
          // signUpWarn.current.classList.add('error');
          setErrorText('Must enter an email')
        } else if(error.includes('Path `password` is required.')){
          // signUpWarn.current.classList.add('error');
          setErrorText('must enter a password error')
        }
        
        signUpWarn.current.classList.remove('opacity-0');
      }
    } else {
      signUpWarn.current.classList.remove('opacity-0');
      // signUpWarn.current.classList.add('error');
          setErrorText('Must enter a valid email address & password, password must be at least 7 characters long')
    }
    };
    console.log(errorText)
    const handleSignupChange = (event) => {
      const { name, value } = event.target;
      console.log(signupFormState)
      signUpWarn.current.classList.add('opacity-0');
      // signUpWarn.classList.remove('error');
      //   signUpWarn.classList.remove('error');
      //   signUpWarn.classList.remove('error');
      setSignupFormState({
        ...signupFormState,
        [name]: value,
      });
      
    };
  
    //show Passowrd
    const signupPass = useRef();
    const showPassword = () => {
      if(signupPass.current.type === 'password'){
        signupPass.current.type = 'text'
      } else {
        signupPass.current.type = 'password'
      }
    }

return (
  <div className="lpbg">
  <div className='lpbgOverlay'>
    <section className='flex sm:flex-nowrap flex-wrap space-between w-11/12 md:w-[1200px] m-auto py-20 md:px-8'>
    <article className='sm:w-3/5 w-full m-auto'>
        <h1 className='w-[90%] text-left font-bold text-[40px]'>Elevate Your Vacation Rental Experience</h1>
        <br />
        <h3 className='w-3/4 text-left font-semibold text-[20px]'>Enhance your guests' stay and streamline your hosting tasks with Vacanze's innovative features. 
        <br/>
        <br/>
        Stand out from the crowd by customizing every aspect of your rental experience, from personalized locations on our integrated Google Map to automated guest interactions. 
        <br/>
        <br/>Welcome to a new level of hospitality excellence.
        </h3>

      </article>
      <article className='sm:w-1/2 w-full m-auto'>
        {/* <h1 className='text-[40px] font-bold'>Create Your Digital guest book</h1> */}
        <div className='border-[1px] rounded-lg py-3 shadow md:w-[470px] w-7/8 m-auto bg-white pb-3'>
          <h2 className='w-[90%] font-bold text-2xl mb-2 mt-3 m-auto px-5'>Create an account to get started</h2>
          <p className='w-[90%] mr-auto text-md font-semibold m-auto pl-5 pr-8 mb-3 '>use code <strong>TRYVACANZE</strong> at checkout for 50% off!</p>
          <p className='w-[90%] text-left m-auto text-md font-bold'>enter email:</p>
          <input name="eml" onChange={handleSignupChange}  className='w-[90%] border-[2px] rounded-lg px-2 pt-1 mb-2' placeholder='email@example.com'/>
          <p className='w-[90%] text-left m-auto text-md font-bold'>enter password:</p>
          <input name="psw" onChange={handleSignupChange}  className='w-[90%] border-[2px] rounded-lg px-2 py-2' placeholder='password'/>
          <div ref={signUpWarn} className='opacity-0 font-bold text-red-600'>({errorText})</div>
          <div className='mb-3 m-auto w-fit px-5'>
            <button onClick={handleSignupFormSubmit} className='mainColor text-white font-bold pt-2 pb-1 px-12 rounded-lg mr-auto'>Sign Up</button>
          </div>
        </div>
      </article>
      
    </section>
    <section className='flex w-11/12 md:w-[1200px] m-auto mt-10 pb-20 mb-20 font-bold sm:text-2xl text-sm'>
      <article className='w-1/3'><img className='m-auto my-5 w-[180px]' src={infoPrev} />
        <div className='m-auto w-3/4'>Share all important information about your rental with your guests</div>
        
      </article>
      <article className='w-1/3'><img className='m-auto my-5 w-[180px]' src={mapPrev} />
        <div className='m-auto w-3/4'>Add custom loctions to your app's intergrated Google Map.</div>
        
      </article>
      <article className='w-1/3'><img className='m-auto my-5 w-[180px]' src={homePrev} />
        <div className='m-auto w-3/4'>Save yourself time by automating guest interactions</div>
        
      </article>
    </section>
    <section className='flex sm:flex-nowrap flex-wrap space-between w-11/12 md:w-[1200px] m-auto py-12 md:px-8  pb-20'>
      <article className='m-auto sm:w-3/5 w-full'>
      <img src={anini} className='md:w-[400px] w-4/5 sm:mr-auto m-auto'/>
      </article>
      
      <article className=' w-full sm:w-1/2 m-auto'>
        <h1 className='w-full text-left font-bold text-[40px]'> Transform Your Rental Business Today</h1>
        <br />
        <h3 className='w-full text-left font-semibold text-[20px]'>Take your vacation rental to new heights with Vacanze. Impress guests with personalized touches and seamless interactions, all effortlessly managed through our customizable app. Let Vacanze be your partner in providing unforgettable experiences for your guests while saving you valuable time.</h3>

      </article>
    </section>
    </div>
  </div>
)
};

export default LandingPage;