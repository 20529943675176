import React, { useState, useRef } from "react"
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useStoreContext } from "../../../../utils/GlobalState";
import { useMutation } from "@apollo/client";
import { ADD_POI, UPDATED_POI, DELETE_POI } from "../../../../utils/mutations";
import Select from 'react-select';
import { BsQuestionCircle } from 'react-icons/bs'
import { FaRegTrashAlt } from 'react-icons/fa' 
import { AiOutlineEdit } from 'react-icons/ai'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import x from '../../../../assets/x.png'
import whiteLogo from '../../../../assets/icons/light512.png'
import Home from '../../../../assets/mappityMarkers/Home_6.png'
import Bar from '../../../../assets/mapIcons/Bar.png';
import Beach from '../../../../assets/mapIcons/Beach.png';
import Bike from '../../../../assets/mapIcons/bike.png';
import Boat from '../../../../assets/mapIcons/Boat.png';
import Camera from '../../../../assets/mapIcons/Camera.png';
import Coffee from '../../../../assets/mapIcons/Coffee.png';
import Entertainment from '../../../../assets/mapIcons/Entertainment.png';
import Forest from '../../../../assets/mapIcons/Forest.png';
import Gasoline from '../../../../assets/mapIcons/Gasoline.png';
import Golf from '../../../../assets/mapIcons/Golf.png';
import Groceries from '../../../../assets/mapIcons/Groceries.png';
import Hike from '../../../../assets/mapIcons/Hike.png';
import Medical from '../../../../assets/mapIcons/Medical.png';
import Park from '../../../../assets/mapIcons/Park.png';
import Restaurant from '../../../../assets/mapIcons/Restaurant.png';
import Shopping from '../../../../assets/mapIcons/Shopping.png';
import Swimming from '../../../../assets/mapIcons/Swimming.png';
import Worship from '../../../../assets/mapIcons/Worship.png';

import mapPic from '../../../../assets/map.png'

import SimpleFileUpload from "react-simple-file-upload";





const MarkedLocations = () => {
  const [state] = useStoreContext();
  const guideId = state?.guide?._id;

  const [formState, setFormState] = useState();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  //GOOGLE MAPS DROP PIN STUFF
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc',
  })
  const [map, setMap] = useState( /** @type google.maps.map */ (null));
  const [location, setLocation] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: parseFloat(state?.guide?.poi[0]?.lat) || 10, lng: parseFloat(state?.guide?.poi[0]?.lng) || 10
  })
  console.log(defaultCenter)
  const [markerCenter, setMarkerCenter] = useState()

  const handleMarkerClick = (event) => {
    setMarkerCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() })
    setDefaultCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() })
  }
// END GOOGLE MAPS DROP PIN STUFF'

//toggle address or map variables
const [addressActive, setAddressActive] = useState(true);
const [mapActive, setMapActive] = useState(false);
let formContain = useRef();
let addressClick = useRef();
let mapClick = useRef();

const handleAddress = (type) => {
  if(type === 'address') {
    setAddressActive(true);
    setMapActive(false)
  } else if(type === 'map') {
    console.log(mapClick.classList)
    setAddressActive(false);
    setMapActive(true);
    setDefaultCenter({
      lat: parseFloat(state?.guide?.poi[0]?.lat),
      lng: parseFloat(state?.guide?.poi[0]?.lng)
    })
    setFormState({
      ...formState,
      address: null,
    });
    // this doesnt work yet 
    formContain.current.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }
}

  const places = state?.guide?.poi?.filter(place => 
    place.name !== 'home');

    const [editMark, setEditMark] = useState(false);

  const [activeAdd, setActiveAdd] = useState(false);

  const [imageFile, setImageFile] = useState();
  const handleImageFile = (url) => {
    setImageFile(url)
  }


  const [addPoi] = useMutation(ADD_POI);
  const handleSavePoi = async () => {
    try {
      let newLocation;
      if(formState?.address !== null){
        let beforeRemoveApt = formState.address.split(' ');
        const filteredAddress = beforeRemoveApt.filter(str => !str.startsWith("#"));
        let geocodeAddress = filteredAddress.join('%20').concat('&');
        // let geocodeAddress = formState.address.split(' ').join('%20').concat('&');

      newLocation = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocodeAddress}key=AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc`)
        .then(Response => Response.json())
        .then((data) => {
          const newLatLong = data.results[0].geometry.location;
          return newLatLong;
        })
        .catch((err) => console.log(err));
      } else {
        newLocation = markerCenter
      }
      await addPoi({
        variables: {
          guideId: guideId,
          name: formState.name,
          address: formState.address,
          description: value,
          type: selectedTheme.value,
          lat: newLocation.lat,
          lng: newLocation.lng,
          pic: imageFile
        }
      });

      let name = document.getElementsByName('name');
      name.value = '';
      let theme = document.getElementsByName('theme');
      theme.value = '';
      let description = document.getElementsByName('description');
      description.value = '';
      let address = document.getElementsByName('address');
      address.value = '';
      setValue('');
      setDefaultCenter({
        lat: parseFloat(state?.guide?.poi[0]?.lat),
        lng: parseFloat(state?.guide?.poi[0]?.lng)
      });
      setActiveAdd(false);
    } catch (e) {
      console.log(e);
    }
  }
  //type selection
  const [selectedTheme, setSelectedTheme] = useState(Beach);
  const typeOptions = [
    {value: 'Bar', label: (
      <img className="h-20" alt="Bar" src={Bar} />
    )},
    {value: 'Beach', label: (
      <img className="h-20" alt="Beach" src={Beach} />
    )},
    {value: 'Bike', label: (
      <img className="h-20" alt="Bike" src={Bike} />
    )},
    {value: 'Boat', label: (
      <img className="h-20" alt="Boat" src={Boat} />
    )},
    {value: 'Coffee', label: (
      <img className="h-20" alt="Coffee" src={Coffee} />
    )},
    {value: 'Camera', label: (
      <img className="h-20" alt="Camera" src={Camera} />
    )},
    {value: 'Entertainment', label: (
      <img className="h-20" alt="Entertainment" src={Entertainment} />
    )},
    {value: 'Forest', label: (
      <img className="h-20" alt="Forest" src={Forest} />
    )},
    {value: 'Food', label: (
      <img className="h-20" alt="Food" src={Restaurant} />
    )},
    {value: 'Gasoline', label: (
      <img className="h-20" alt="Gasoline" src={Gasoline} />
    )},
    {value: 'Golf', label: (
      <img className="h-20" alt="Golf" src={Golf} />
    )},
    {value: 'Groceries', label: (
      <img className="h-20" alt="Groceries" src={Groceries} />
    )},
    {value: 'Trail', label: (
      <img className="h-20" alt="Trail" src={Hike} />
    )},
    {value: 'Medical', label: (
      <img className="h-20" alt="Medical" src={Medical} />
    )},
    {value: 'Park', label: (
      <img className="h-20" alt="Park" src={Park} />
    )},
    {value: 'Shopping', label: (
      <img className="h-20" alt="Shopping" src={Shopping} />
    )},
    {value: 'Swim', label: (
      <img className="h-20" alt="Swim" src={Swimming} />
    )},
    {value: 'Worship', label: (
      <img className="h-20" alt="Medical" src={Worship} />
    )},
  ];

  const styles = {
    container: base => ({
      ...base,
      flex: 1,
    })
  };

  //REACT Quill
  const [value, setValue] = useState('');

  const handleQuill = (e) => {
    setValue(e)
    console.log(e)
    setFormState({
      ...formState,
      description: value
    });
  }
  console.log(value);
  //delete POI
  const [deletePoi] = useMutation(DELETE_POI);
  const handleDeletePoi = async (poiId) => {
    await deletePoi({
      variables: {
        guideId: guideId,
        poiId: poiId
      }
    });
  }

  const iconPreview = (icon) => {
    switch (icon){
      case 'Bar':
        return <img className="w-16 h-20" alt="icon" src={Bar} />;
      case 'Beach':
        return <img className="w-20 h-24" alt="icon" src={Beach} />;
      case 'Bike':
        return <img className="w-20 h-24" alt="icon" src={Bike} />;
      case 'Boat':
        return <img className="w-20 h-24" alt="icon" src={Boat} />;
      case 'Camera':
        return <img className="w-20 h-24" alt="icon" src={Camera} />;
      case 'Coffee':
        return <img className="w-20 h-24" alt="icon" src={Coffee} />;
      case 'Entertainment':
        return <img className="w-20 h-24" alt="icon" src={Entertainment} />;
      case 'Forest':
        return <img className="w-20 h-24" alt="icon" src={Forest} />;
      case 'Food':
        return <img className="w-20 h-24" alt="icon" src={Restaurant} />;
      case 'Gasoline':
        return <img className="w-20 h-24" alt="icon" src={Gasoline} />;
      case 'Golf':
        return <img className="w-20 h-24" alt="icon" src={Golf} />;
      case 'Groceries':
        return <img className="w-20 h-24" alt="icon" src={Groceries} />;
      case 'Trail':
        return <img className="w-20 h-24" alt="icon" src={Hike} />;
      case 'Medical':
        return <img className="w-20 h-24" alt="icon" src={Medical} />;
      case 'Park':
        return <img className="w-20 h-24" alt="icon" src={Park} />;
      case 'Shopping':
        return <img className="w-20 h-24" alt="icon" src={Shopping} />;
      case 'Swim':
        return <img className="w-20 h-24" alt="icon" src={Swimming} />;
      case 'Worship':
        return <img className="w-20 h-24" alt="icon" src={Worship} />;
      default:
        break;
    }
  }

  const [activeLocation, setActiveLocation] = useState();
  const [editFormState, setEditFormState] = useState();

  const [editMapLocation, setEditMapCoordiantes] = useState();
  const [editQuillValue, setEditQuillValue] = useState()

  const handleEditQuill = (e) => {
    setEditQuillValue(e)

  }

  const activateChange = (e) => {
    let changeLogo = document.getElementById('changeLogo');
    console.log(changeLogo)
    let deleteBtn = e.currentTarget.parentNode.children[2];
    deleteBtn.classList.remove('text-white')
    deleteBtn.classList.remove('bg-red-600')
    const changeBtn = e.currentTarget;
    changeBtn.classList.add('text-white');
    changeBtn.classList.add('bg-cyan-900');
    e.currentTarget.parentNode.parentNode.children[1].classList.remove('hidden');

    // if(simple file uplaod has value){
      // setImageFile(simpleFileUploadValue)
    // } else {
      // setImageFile(Original url)
    // }
    
  }
  const deleteImage = (e) => {
    let deleteBtn = e.currentTarget;
    deleteBtn.classList.add('text-white')
    deleteBtn.classList.add('bg-red-600')
    const changeBtn = e.currentTarget.parentNode.children[0];
    changeBtn.classList.remove('text-white');
    changeBtn.classList.remove('bg-cyan-900');
    e.currentTarget.parentNode.parentNode.children[1].classList.add('hidden')
    setImageFile('')
  }
  
  const openEdit = (place) => {
    if(place?.address){
      setAorM('a')
    }else {
      setAorM('m')
    }
    setEditMapCoordiantes({lat:place.lat, lng: place.lng})
    setEditMark(true)
    setActiveLocation(place);
    setSelectedTheme(place.type)
    setEditFormState({
      editName: place.name,
      editaddress: place.address,
      editDescription: place.description,
      editIcon: place.type
    })
    setEditQuillValue(place.description)
  }

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormState({
      ...editFormState,
      [name]: value,
    });
  };
  const handleEditIconChange = async(event) => {
    await setSelectedTheme(event.value)
    setEditFormState({
      ...editFormState,
      editIcon: event.value,
    });
  };

  const [updatePoi] = useMutation(UPDATED_POI);
  const editIcon = useRef();
  
  
  const saveEditPoi = async() => {
    console.log(editFormState.editaddress)

    //if AorM is 'a' do update with address and geocode address

    //if AorM is 'm' do update with no address and markerCenter.lat at lat & markerCenter.lng as lng
    if(AorM === 'a'){
      let beforeRemoveApt = editFormState.editaddress.split(' ');
      const filteredAddress = beforeRemoveApt.filter(str => !str.startsWith("#"));
      let geocodeAddress = filteredAddress.join('%20').concat('&');
    // let geocodeAddress = editFormState.editaddress.split(' ').join('%20').concat('&');
    const newLocation = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocodeAddress}key=AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc`)
        .then(Response => Response.json())
        .then((data) => {
          console.log(data);
          const newLatLong = data.results[0].geometry.location;
          return newLatLong;
        })
        .catch((err) => console.log(err));

        
    const updatedGuidePoi = await updatePoi({
      variables: {
        guideId: guideId,
        name: activeLocation.name,
        newName: editFormState.editName,
        lat: newLocation.lat,
        lng: newLocation.lng,
        type: selectedTheme,
        address: editFormState.editaddress,
        description: editQuillValue,
        pic: imageFile
      }
    });
  }
  if(AorM === 'm'){
    const updatedGuidePoi = await updatePoi({
      variables: {
        guideId: guideId,
        name: activeLocation.name,
        newName: editFormState.editName,
        lat: editMapLocation.lat,
        lng: editMapLocation.lng,
        type: selectedTheme,
        description: editQuillValue,
        pic: imageFile
      }
    });
  }

    setEditMark(false)
  }

  const [toggleImg, setToggleImg] = useState(false)

  const [AorM, setAorM] = useState('a');


  return (
    <>
    <section className="mt-5 w-full flex flex-wrap bg-gray-50 pt-4 rounded-lg">
        <div className="w-full flex flex-wrap justify-center md:flex-nowrap mb-20">
          {/* <p className="w-0 md:w-40"></p> */}
          <div className="w-full md:w-max m-3 md:text-2xl font-bold flex ">Add Locations to your map that your would like your guests to know about.
          <div className="tooltip">
          <div className="top max-w-[90vw]">
            <h3 className="font-bold">Marked Locations</h3>
            <p className="text-md">These Locations will show up on your guide's map, you will also be able to find them in the 'Guide' section of your guide under 'Locations'.</p>
            <i></i>
          </div>
          <BsQuestionCircle className=" text-xs mr-2 text-gray-500 relative ml-2" style={{ top: '7px'}} />
          </div>
          </div>
          <button className="border-2 border-cyan-700 w-max md:m-0 rounded-md px-2 h-10 text-xl text-white font-bold mainColor py-1 px-6 font-bold mt-2" onClick={() => setActiveAdd(true)}>
            ➕ Add Location
          </button>
        </div>
        <div className="w-full m-auto flex flex-wrap justify-center">
        {places?.length === 1 && (
          <div>
            <h3 className="font-bold text-xl">Here is where you will add places to your guide's map, below is an example of what this will look like.</h3>
            <img className="m-auto my-8" src={mapPic} />
            <button className="border-2 border-cyan-700 w-max md:m-0 rounded-md px-2 h-10 text-sm md:text-xl text-white font-bold mainColor pt-1 px-6 font-bold mt-2 my-4" onClick={() => setActiveAdd(true)}>
              Click Here to Start Adding to Your Map
            </button>
          </div>
        )}
        {places?.map((place, index) => {
          if(index === 0) {
            return;
          }
          console.log(place)
          return(
            <div key={place?._id} className=" h-fit w-full md:w-1/3 max-w-[350px] p-3 pt-0 px-1 m-2 mb-[70px] rounded-md guideShadow bg-white">
               {/* border-cyan-600 */}
                <div className={place?.type ? "flex m-auto w-fit  mb-[-20px] mt-[-60px] relative z-20" :"flex m-auto w-fit  relative z-20"}>
                {place?.address === state?.guide?.address ? (<p><img className="w-20 h-20" alt="icon" src={Home} /></p>
                ):(
                  iconPreview(place?.type)
                )}
              </div>
              {place?.pic ? (
              <img className="max-h-[220px] m-auto object-contain rounded-md mt-2 shadow mb-5" src={place?.pic} />
              ):(<div className="p-5"></div>)}
              <div className="justify-between px-2">
                <h4 className="pr-3 font-bold text-xl mb-2 mr-auto homeText text-left">{place?.name}</h4>
                <div id="subCatdescription" style={{ marginLeft: '0px', important: 'true' }} className="ml-0 text-left text-sm" dangerouslySetInnerHTML={{__html: place?.description}} />
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-around px-2 mb-4">
                <div className="mr-auto w-full">
                  <div className="mr-auto w-fit">
                  {place.address !== null ?(
                    <a className='text-left text-blue-500 font-semibold underline my-2 mr-auto' href={'https://maps.google.com/?q=' + place.address} target='_blank'>{place.address}</a>
                  ) : (
                    <a className='text-left text-blue-500 font-semibold underline my-2 mr-auto w-full' href={'https://maps.google.com/?q=' + place.lat + ',' + place.lng} target='_blank'>Get Directions</a>
                  )}
                  </div>
                  
                  {/* <p className="pt-2 text-left">{place?.description}</p> */}
                </div>
                
              </div>

              {place?.address !== state?.guide?.address ? (
                <div className="flex w-full">
                  <button onClick={() => openEdit(place)} className="text-sm md:text-lg relative border-2 border-cyan-900 rounded h-8 flex px-2 font-bold ml-auto mr-3" >
                    <AiOutlineEdit className="mr-1 mt-1"/> Edit
                  </button>
                  <button className="mr-auto text-sm md:text-lg relative border-2 border-red-600 rounded h-8 flex px-2  font-bold" onClick={() => handleDeletePoi(place?._id)}>
                    <FaRegTrashAlt className="mr-1 mt-1"/> Delete
                  </button>
                </div>
                ) : (
                  <p className="opacity-0">🗑️</p>
                )}
            </div>
          )
        })}
        </div>
      </section>
    {(activeAdd) && (
      <div className="sticky bottom-0 z-50">
        <div className="fixed top-0 left-0 opacity-30 bg-gray-600 w-screen h-screen"> </div>
      <div className="fixed top-80  left-0 z-40 flex justify-around w-full mx-auto">
        <div className="rounded-md bg-white border-2 flex flex-wrap md:w-[800px] h-[80vh] md:h-[80vh] mt-16 md:mt-0 mx-3 md:mx-0 z-20 relative bottom-64 mx-auto">
        <div className="  p-3 pt-0 flex w-full justify-between homeText font-bold">
          <img src={whiteLogo} className="w-[50px] h-[50px] mr-8"/>
          <div className="mr-auto">
          <h3 className="text-xl mr-auto mt-6 text-left">Add a location to your map</h3> 
          <p className="text-sm text-left text-gray-500">These are the locations that will show up on your in-app map</p>
          </div>
          <button onClick={() => setActiveAdd(false)} className='rounded px-2 text-xl mb-auto mt-4'><img src={x}/></button>
        </div> 
        <section ref={formContain} className="max-h-[60%]  md:max-h-[80%] overflow-y-auto pt-2 pl-6 pr-6">
      <div className="w-full flex flex-wrap  pb-6 homeText w-full">
      <div className="w-full mb-4">
            <p className="mr-auto text-left font-bold">Location Name:</p>
            <input className="w-full pl-2 pt-1 border-2 rounded-md mr-auto" name="name" onChange={handleFormChange} type='text' placeholder='Name'/>
      </div>
      <div className="w-full mb-3">
            <p className="font-bold text-left mr-auto">Location Description:</p>
            {/* <textarea className="w-4/5 pl-2 pt-1 border-2 rounded-md" name="description" onChange={handleFormChange} type='text' placeholder='description'/> */}
            <ReactQuill theme="snow" value={value} onChange={handleQuill} name="description" placeholder='Description' className='w-full m-auto'/>
          </div>
      <div className="w-full md:w-1/2">
              <p className="mt-auto font-bold w-full md:text-center mb-2">Select an Icon for the location</p>
              <div className="m-auto w-fit flex align-center">
                <Select
                name="theme"
                className="text-black m-auto mt-auto flex mb-auto h-28 w-40 w-full"
                styles={styles}
                defaultValue={selectedTheme}
                onChange={setSelectedTheme}
                options={typeOptions}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-wrap mb-auto  ">
          <section className="w-full flex jusitfy-between">
            
            <div className="w-full">
              <h2 className="font-bold flex text-center m-auto w-fit mb-2">Add Picture <span className=" mt-1 ml-1 text-gray-500 text-sm font-bold">(not required)</span></h2>
              <div className="m-auto w-fits flex justify-center">
                <SimpleFileUpload
                className='m-auto'
                  apiKey="b0c9e9b3278499b0b02bced650795a13"
                  preview="true"
                  onSuccess={handleImageFile}
                />
              </div>
              
            </div>
          </section>
        
          
        </div>

      </div>
      <div className="w-full mb-3">
          <div className="flex w-fit justify-center mr-auto">
            <p className="mr-3">address:</p>
            <div className="rounded-lg border-2 border-black mb-4">
            {addressActive ? (
            <button ref={addressClick} onClick={() => handleAddress('address')} className="mainColor text-white font-bold px-4 pt-1 rounded">Enter Address</button>
            ) : (
              <button ref={addressClick} onClick={() => handleAddress('address')} className="font-bold px-4 pt-1 rounded">Enter Address</button>
            )}
            {addressActive ? (
            <button ref={mapClick} onClick={() => handleAddress('map')}  className="font-bold px-4 pt-1 ">Mark on Map</button>
            ) : (
              <button ref={mapClick} onClick={() => handleAddress('map')}  className="mainColor text-white font-bold px-4 pt-1 rounded">Mark on Map</button>
            )}
            </div>
          </div>
          {addressActive && (
            <>

            <input className="w-full pl-2 pt-1 border-2 rounded-md" name="address" onChange={handleFormChange} type='text' placeholder='Address'/>
            </>
          )}
            
          </div>
      {mapActive && (
      <div className="h-[200px] md:h-[300px] w-full rounded">
        <GoogleMap center={defaultCenter} zoom={13} 
          mapContainerStyle={{width: '100%', height: '100%'}}
          onLoad={(map) => setMap(map)}
          options={{
            fullscreenControl: false,
            zoomControlOptions: { position: 3 }
          }}
          onClick={(event) => {
            handleMarkerClick(event)
          }
          }
        >
          <Marker
            position={markerCenter}
            draggable={true}
            
            onDragEnd={(event) => setMarkerCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() })}
          />
        </GoogleMap>
      </div>
      )}
      </section>
      <div className="flex justify-center w-full py-3 border-t-2">
        {/* <button onClick={() => setActiveAdd(false)} className='border-red-600 border-2 text-red-600 font-extrabold rounded-lg px-2 m-1 mr-5 text-xl'>Cancel</button> */}
        <button onClick={() => handleSavePoi()} className="border-2 border-cyan-900 mainColor text-white font-extrabold rounded-xl px-20 m-1">Save Location</button>
      </div>
      </div>
      </div>
      </div>
    )}
    {(editMark) && (
      <div className="w-screen h-screen absolute"  style={{marginBottom: '-25rem'}}>
        <div className="w-screen h-screen fixed top-0 bg-gray-400 opacity-50"></div>
        <div className="fixed top-24 md:top-10 md:bottom-40 w-full m-auto max-h-[95%] md:max-h-[90%]">
          
          <div className="bg-white border-2 rounded-lg border-cyan-900 m-auto w-11/12 md:w-3/4 lg:w-1/2 h-[90vh]">
          <div className="  p-3 pt-0 flex w-full justify-between homeText font-bold">
          <img src={whiteLogo} className="w-[50px] h-[50px] mr-8"/>
          <div className="mr-auto">
          <h3 className="text-xl mr-auto mt-6 text-left">Edit Location</h3> 
          <p className="text-sm text-left text-gray-500">Make changes to your map markers here</p>
          </div>
          <button onClick={() => setEditMark(false)} className='rounded px-2 text-xl mb-auto mt-4'><img src={x}/></button>
        </div> 
            <div className="p-3 pl-6 pr-6 w-full m-auto max-h-[70%] md:max-h-[78%] overflow-y-scroll">
              <div className="flex flex-wrap justify-between">
                  <h2 className="w-full text-left font-bold">Location Name:</h2>
                  <input name='editName' defaultValue={activeLocation.name} onChange={handleEditChange} className=' w-11/12 md:w-full border-2 rounded-md px-2 m-auto'/>
              </div>
              
              <div className="flex flex-wrap justify-between mt-5">
                  <h2 className="w-full text-left font-bold">Description:</h2>
                  <ReactQuill theme="snow" value={editQuillValue} onChange={handleEditQuill} name="description" placeholder='Description' className='w-full m-auto'/>
                  {/* <textarea name="editDescription" defaultValue={activeLocation.description} onChange={handleEditChange} className='h-40 md:ml-3 w-11/12 md:w-5/6 border-2 rounded-md px-2'/> */}
              </div>
              <div className="my-5 flex m-auto">
                <div className="border-2 border-gray-200 rounded-lg">
              {AorM === 'a' ? (
            <button onClick={() => setAorM('a')} className="mainColor text-white font-bold px-4 pt-1 rounded-md ml-auto">Edit Address</button>
            ) : (
              <button onClick={() => setAorM('a')} className="text-black font-bold px-4 pt-1 rounded-md ml-auto">Edit Address</button>
            )}
            {AorM === 'm' ? (
            <button onClick={() => setAorM('m')} className="mainColor text-white font-bold px-4 pt-1 rounded-md">Edit Map Location</button>
            ) : (
              <button onClick={() => setAorM('m')} className=" text-black font-bold px-4 pt-1 rounded-md">Edit Map Location</button>
            )}
            </div>
                <div className="my-auto tooltip mr-auto">
                  <div className="top">
                    <h3 className="font-bold">Changing Address / Map Marker</h3>
                    <p className="text-md">Choose either an address or map marker.  Entering and address will also update your map marker's location, but if you change the map location, an address is not necessary.</p>
                    <i></i>
                  </div>
                  <BsQuestionCircle className=" text-sm mr-2 text-gray-500 relative ml-3" style={{ top: '2px'}}/>
                </div>
                
              </div>
              {AorM === 'a' && (
                <div className="flex flex-wrap md:flex-nowrap justify-between my-5">
                  <h2 className="w-full md:w-24 text-left font-bold">Address:</h2>
                  <input name='editaddress' defaultValue={activeLocation.address} onChange={handleEditChange} className='md:ml-3 w-11/12 md:w-5/6 border-2 rounded-md px-2'/>
              </div>
              )}
              {AorM === 'm' && (
                <div className="h-[200px] md:h-[300px] w-full mb-5">
                  <GoogleMap center={editMapLocation} zoom={13} 
                    mapContainerStyle={{width: '100%', height: '100%'}}
                    onLoad={(map) => setMap(map)}
                    options={{
                      fullscreenControl: false,
                      zoomControlOptions: { position: 3 }
                    }}
                    onClick={(event) => setEditMapCoordiantes({ lat: event.latLng.lat(), lng: event.latLng.lng() })}>

                    <Marker
                      position={editMapLocation}
                      draggable={true}
                      
                      onDragEnd={(event) => setEditMapCoordiantes({ lat: event.latLng.lat(), lng: event.latLng.lng() })}
                    />
                  </GoogleMap>
                </div>
              )}
              
              <div className="flex flex-wrap  justify-between mt-12">

                  <div className="flex w-2/3 mb-6">
                    <h2 className="md:mr-auto w-full md:w-fit font-bold text-left">Map Icon:</h2>
                    
                  
                  <div className="w-full md:w-1/2">
                  <Select
                    name="editIcon"
                    ref={editIcon}
                    className="text-black m-auto flex mb-auto w-full"
                    styles={styles}
                    defaultValue={selectedTheme}
                    onChange={handleEditIconChange}
                    options={typeOptions}
                  />
                  </div>
                  <div>{iconPreview(editFormState.editIcon)}</div>
                </div>
                <div className="my-5 w-full ">
                <h2 className="md:mr-auto w-full md:w-fit font-bold text-left">Location Image:</h2>
                  <h2 className="flex m-auto w-full justify-center">
                    <button onClick={activateChange} className="border-2 px-4 py-1 rounded-md border-cyan-900">Change Image here</button> 
                    <p className="mx-4 my-auto font-semibold">or</p> 
                    <button onClick={deleteImage} className="border-2 px-4 py-1 rounded-md border-red-600">Delete Image</button> 
                    
                  </h2>
                  <div className="m-auto w-fit my-4">
                  <SimpleFileUpload
                  id='changeLogo'
                    className='m-auto'
                    apiKey="b0c9e9b3278499b0b02bced650795a13"
                    preview="true"
                    onSuccess={handleImageFile}
                  />
                  </div>
                </div>
                
              </div>
              
            </div>
            <div className="border-t-2">
            <button onClick={saveEditPoi} className="text-white mainColor px-20 py-2 hover:shadow font-bold rounded-md my-3">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  )
};

export default MarkedLocations;