import React, { useState, useRef } from "react";
import { UPDATE_GUIDE_PHONE } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import { formatPhoneNumber } from "../../../utils/formatPhone";

import { useStoreContext } from '../../../utils/GlobalState';

const EditPhone = (props) => {
  console.log(props)
  const [activeEdit, setActiveEdit] = useState(false)  

  const [state, dispatch] = useStoreContext();
  const guideId = state.guide?.guideId;
  // console.log(state?.guide)

  const [updateGuidePhone] = useMutation(UPDATE_GUIDE_PHONE);

  const phoneRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const handlePhoneInput = e => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhone);
  }

  const handleEditPhone = async() => {
    console.log(phoneRef.current.value)
    let phoneNum = formatPhoneNumber(phoneRef.current.value)
    console.log(phoneNum)
    const updatedGuidePhone = await updateGuidePhone({
      variables: {
        guideId: props.guideId,
        contactPhone: inputValue
      }
    })
    // console.log('updatedGuidePhone ', updatedGuidePhone)
    // updateState(updatedCategory)
    // setActiveEditSubCategory(false)
  }




  return (
    <div className="mt-5">
    {activeEdit ? (<div className=" flex flex-wrap justify-between">
      edit phone
      <input ref={phoneRef} type="text" onChange={handlePhoneInput} name='phone' placeholder="(800) 248-6789" maxLength={15} className="inputText font-bold border-2 border-gray-200 text-black mx-2 rounded shadow w-full md:w-1/2 text-center" 
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={inputValue}
          />
          <button onClick={() => handleEditPhone()} className="mainColor text-white font-bold p-2 px-4 rounded-md">
            Save Change
          </button>
    </div>):(
      <div className="flex flex-wrap justify-between align-center">
        <div className="opacity-0">Edit Phome Number</div>
        <p className="flex m-auto">{props.guidePhone}</p>
        <button onClick={() => setActiveEdit(true)} className="mainColor text-white font-bold p-1 md:p-2 rounded-md w-1/3 md:w-1/4 py-1 md:py-3 mx-1 md:mx-5">Edit Phome Number</button>
      </div>
      )}
    
    </div>
  )
}

export default EditPhone;