import React, { useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useStoreContext } from '../../../../utils/GlobalState';

import Star from '../../../../assets/mapIcons/Star.png'
import Bar from '../../../../assets/mapIcons/Bar.png';
import Beach from '../../../../assets/mapIcons/Beach.png';
import Bike from '../../../../assets/mapIcons/bike.png';
import Boat from '../../../../assets/mapIcons/Boat.png';
import Camera from '../../../../assets/mapIcons/Camera.png';
import Coffee from '../../../../assets/mapIcons/Coffee.png';
import Entertainment from '../../../../assets/mapIcons/Entertainment.png';
import Forest from '../../../../assets/mapIcons/Forest.png';
import Gasoline from '../../../../assets/mapIcons/Gasoline.png';
import Golf from '../../../../assets/mapIcons/Golf.png';
import Groceries from '../../../../assets/mapIcons/Groceries.png';
import Hike from '../../../../assets/mapIcons/Hike.png';
import Medical from '../../../../assets/mapIcons/Medical.png';
import Park from '../../../../assets/mapIcons/Park.png';
import Restaurant from '../../../../assets/mapIcons/Restaurant.png';
import Shopping from '../../../../assets/mapIcons/Shopping.png';
import Swimming from '../../../../assets/mapIcons/Swimming.png';
import Worship from '../../../../assets/mapIcons/Worship.png';


// NEED TO ADD LAT AND LNG TO GUIDE SCHEMA AND USE GLOBAL STATE TO GET LAT AND LNG IN THIS COMPONOENT, STOP USING PROPS
const MapContainer = ({pois}) => {
  const [state] = useStoreContext();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc',
  })

  const [map, setMap] = useState( /** @type google.maps.map */ (null));
  
  const home = pois?.find(({ name }) => name === state?.guide?.name);

  const defaultCenter = {
    lat: parseFloat(home?.lat) || 10, lng: parseFloat(home?.lng) || 10
  }
// Bar Beach Bike Boat Camera Coffee Entertainment Forest Gasoline Golf Groceries Hike Medical Park Shopping Swim Worship
  const showIcon = (type) => {
    switch(type) {
      case 'Bar':
        return Bar;
      case 'Beach':
        return Beach;
      case 'Bike':
        return Bike;
      case 'Boat':
        return Boat;
      case 'Camera':
        return Camera;
      case 'Coffee':
        return Coffee;
      case 'Entertainment':
        return Entertainment;
      case 'Forest':
        return Forest;
      case 'Gasoline':
        return Gasoline;
      case 'Golf':
        return Golf;
      case 'Groceries':
        return Groceries;
      case 'Trail':
        return Hike;
      case 'Medical':
        return Medical;
      case 'Park':
        return Park;
      case 'Food':
        return Restaurant;
      case 'Shopping':
        return Shopping;
      case 'Swim':
        return Swimming;
      case 'Worship':
        return Worship;
    }
  }

  const [selectedLocation, setSelectedLocation] = useState(null);

  if(!isLoaded) {
    return <div> map is loading </div>
  }

  
  return (
    <GoogleMap center={defaultCenter} zoom={11}  mapContainerStyle={{width: '100%', height: '350px'}}
    onLoad={(map) => setMap(map)}
    options={{
      fullscreenControl: false,
      // streetViewControl: false
    }}
    >
      {/* <Marker position={defaultCenter}/> */}
      {pois?.map((place) => {
        if(place?.name === home?.name) {
          return(
            <Marker key={place._id} position={place}
              onClick={() => {
                setSelectedLocation(place); 
              }}
              icon={{
                url: Star,
                scaledSize: new window.google.maps.Size(35,41)
              }}
            />
          )
        }
        return (
          <Marker key={place._id} position={place}
          onClick={() => {
            setSelectedLocation(place); 
          }}
          icon={{
            url: showIcon(place.type),
            scaledSize: new window.google.maps.Size(35,41)
          }}
          />
        )
      })}

      {selectedLocation && (
        <InfoWindow position={selectedLocation}
        onCloseClick={() => {
          setSelectedLocation(null);
        }}>
          <div className='px-2 pb-2 text-lg'>
          <h1 className='font-bold pt-1 pb-2'>{selectedLocation.name}</h1>
          {selectedLocation.address !== null ?(
            <a className='text-blue-500 underline mx-2 my-2' href={'https://maps.google.com/?q=' + selectedLocation.address} target='_blank'>{selectedLocation.address}</a>
          ) : (
            <a className='text-blue-500 underline mx-2 my-2' href={'https://maps.google.com/?q=' + selectedLocation.lat + ',' + selectedLocation.lng} target='_blank'>Get Directions</a>
          )}
          <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: selectedLocation.description}} />
          {/* <p className='pt-2'>{selectedLocation.description}</p> */}
          </div>
        </InfoWindow>
      )}

      {/* <div className='w-full h-full flex justify-between'>
        <p></p>
        <button onClick={() => map.panTo(defaultCenter)} className='centerMapButton bg-gray-50 shadow-lg p-2 m-3 z-20 h-8 grid content-center rounded-sm'><p className='centerMapButtonIcon text-lg'>➤</p></button>
      </div> */}
      
    </GoogleMap>
  )
}
export default MapContainer;


