import React, { useState, useEffect } from "react";

import IOS from "./ios";
import Android from "./android"

const SaveToHome = () => {

  const [width, setWidth] = useState(window.innerWidth);

  const [userDevice, setUserDevice] = useState('');
  const [notDownloaded, setNotDownloaded] = useState(false);

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);

      // CHECK IF USE HAS DOWNLOADED PWA ALREADY
      if (!window.matchMedia('(display-mode: standalone)').matches) {  
        setNotDownloaded(true);
        //  CHECK WHAT TYPE OF PHONE USER IS ON

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          setUserDevice('Windows')
        }

        if (/android/i.test(userAgent)) {
          setUserDevice('Android')
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setUserDevice('IOS')
        }

      }  

    
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

const [isMobile, setIsMobile] = useState(width <= 768);


  return (
  <>
    {(isMobile && notDownloaded ) && (
      <>
      {userDevice === 'IOS' ? (
        <IOS setIsMobile={setIsMobile} />
      ) : (
        <Android setIsMobile={setIsMobile} />
      )
      }
      </>
    )}
            
  </>
    
  
  )
}

export default SaveToHome;