import React, { useState, useRef } from "react";
import { UPDATE_SUBCATEGORY } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SimpleFileUpload from "react-simple-file-upload";

import { useStoreContext } from '../../../utils/GlobalState';

const EditSubCategory = (props) => {
  const setActiveEditSubCategory = props.setActiveEditSubCategory;
  const subCategory = props.subCat;
  const category = props.cat;
  const guideId = props.guideId;
  console.log(props)

  const [state, dispatch] = useStoreContext();
  // console.log(state?.guide)

  const [updateSubCategory] = useMutation(UPDATE_SUBCATEGORY);

  const nameRef = useRef();
  const descriptionRef = useRef();
  const linkRef = useRef();

  const [subFormState, setSubFormState] = useState({
    name: subCategory.name,
    description: subCategory.description,
    link: subCategory.link,
    linkName: subCategory.linkName
  })

  const [quillValue, setQuillValue] = useState(subCategory.description);

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setSubFormState({
      ...subFormState,
      [name]: value,
    });

  }

  const handleQuill = (e) => {
    setQuillValue(e)
    setSubFormState({
      ...subFormState,
      description: quillValue
    });
    console.log(quillValue)
  }

  const [imageState, setImageState] = useState()
  function handleImageFile(url){
    // console.log('The URL of the file is ' + url)
    setImageState(url);
  }

  const handleDeletePicture = (e) => {
    console.log(e.currentTarget)
    const button = e.currentTarget;
    if(button.classList.contains('bg-red-600')){
      console.log('remove red')
      button.classList.remove('bg-red-600')
      button.classList.add('text-red-600')
      button.classList.remove('text-white')
      button.innerHTML = 'Click to delete picture'
    } else{
      button.classList.add('bg-red-600')
      button.classList.add('text-white')
      button.classList.remove('text-red-600')
      button.innerHTML = 'Picture will be deleted on update'
      console.log('add red')
      setImageState('')
    }
  }

  const handleEditSubCat = async() => {
    console.log('1')
    const updatedCategory = await updateSubCategory({
      variables: {
        guideId: guideId,
        categoryId: category._id,
        subCategoryId: subCategory._id,
        name: subFormState.name,
        description: quillValue,
        link: subFormState.link,
        linkName: subFormState.linkName,
        picture: imageState
      }
    })
    console.log('updatedCategory ', updatedCategory)
    // updateState(updatedCategory)
    setActiveEditSubCategory(false)
  }




  return (
    <>
    <div className="fixed top-0 bg-gray-600 opacity-40 w-screen h-screen"></div>
    <div className="fixed bg-white rounded-md top-32 md:top-12 left-[4%] md:left-[35%] w-11/12 md:w-1/3 max-h-[80vh] h-[80vh] overflow-y-scroll m-auto homeText">
        <div className="flex w-full text-2xl justify-between font-bold bg-gray-100 border-b-2 mb-4 rounded-t-lg md:px-3 pt-3">
          <h2 className="mb-2 text-xl  pl-3">Edit Sub-Category</h2>
          <button onClick={() => setActiveEditSubCategory(false)} className="pr-3 hover:shadow-xl">X</button>
        </div>
        <div className="md:max-h-[75%] md:overflow-y-scroll">
        <div className="flex flex-wrap w-[95%] md:w-4/5 m-auto mb-3">
          <p className="mr-2 w-full font-bold">Sub-Category Name: </p>
          <input ref={nameRef} defaultValue={subCategory.name} onChange={handleChangeForm} name='name' className="ml-auto text-black px-2 pt-1 w-full rounded border-2" />
        </div>
        <div className="flex flex-wrap w-[95%] md:w-4/5 m-auto mb-3 mt-8">
          <p className="mr-2 w-full font-bold">Sub-Category Description: </p>
          <ReactQuill theme="snow" value={quillValue} onChange={handleQuill} name="description" placeholder='Description' className='w-full m-auto text-black'/>
        </div>
        <h4 className="w-fit m-auto font-bold mt-8">Change Sub-Category picture here:</h4>
        <div className="w-fit m-auto">
        
        <SimpleFileUpload
              apiKey="b0c9e9b3278499b0b02bced650795a13"
              preview="true"
              onSuccess={handleImageFile}
            />
        </div>
        <div className="mb-3">
          <h2>OR</h2>
          <button onClick={handleDeletePicture} className="border-red-600 border-2 text-red-600 w-fit m-auto px-8 py-2 hover:font-bold rounded-md font-semibold cursor-pointer">Delete Picture here</button>
        </div>
        </div>
        <div className="border-t-2 md:min-h-[40px]">
        <button onClick={handleEditSubCat} className="md:min-h-[40px] m-auto mainColor my-3 px-12 py-1 rounded text-xl font-semibold hover:font-bold text-white  hover:shadow-xl">Update</button>
        </div>
    </div>
    </>
    
  )
}

export default EditSubCategory;