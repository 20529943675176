import { gql } from "@apollo/client";

export const QUERY_USER = gql`
  query user {
    user {
    username
    _id
    email
    subscriptionStatus,
    stripeCustId,
    subId,
    subscriptionName,
    subscriptionPrice,
    subStartDate,
    subscriptionNextPaymentDate,
    subCancelAtEndofPeriod,
    subPayInterval
    guides {
      _id
      address
      name
      contactPhone
      photo
      logo
      categories {
        _id
        name
        description
        icon
        order
        subCategories{
          name
          description
          _id
          link
          linkName
          picture
          parentCategory
        }        
      }
    }
  }
  }
`;

export const QUERY_GUIDE = gql`
  query guide($guideId: ID) {
  guide(guideId: $guideId) {
    _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        name
        description
        icon
        order
        subCategories{
          name
          description
          _id
          parentCategory
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
  }
}
`;

export const QUERY_CLIENT_USER = gql`
  query clientUser($userId: ID) {
  clientUser(userId: $userId) {
    subscriptionStatus
    _id
    username
    email
    changePasswordId
}
}
`;

export const GET_SUB_OPTIONS = gql`
  query getSubscribeOptions {
    getSubscribeOptions{
      id
    name
    priceInCents
    nubmerOfGuides
    }
  }
`;

export const ADMIN_FIND_USER = gql`
  query adminFinduser($email: String) {
    adminFinduser(email: $email){
        username
        _id
        email
        subscriptionStatus,
        stripeCustId,
        subId,
        subscriptionName,
        subscriptionPrice,
        subStartDate,
        subscriptionNextPaymentDate,
        subCancelAtEndofPeriod,
        subPayInterval
        guides {
          _id
          address
          name
          contactPhone
          photo
          logo
          categories {
            _id
            name
            description
            icon
            order
            subCategories{
              name
              description
              _id
              link
              linkName
              picture
              parentCategory
            }        
          }
        }
      
    }
  }
`;

export const ADMIN_ALL_USERS = gql`
  query adminAllUsers($test: String) {
    adminAllUsers(test: $test){
        username
        _id
        email
        subscriptionStatus,
        stripeCustId,
        subId,
        subscriptionName,
        subscriptionPrice,
        subStartDate,
        subscriptionNextPaymentDate,
        subCancelAtEndofPeriod,
        subPayInterval
    }
  }
`;