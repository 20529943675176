import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import sideLogo from '../../../assets/sideLogo.png';
import mainLogo from '../../../assets/icons/Light-png.png'


function Nav() {

  const homeNav = useRef();
  const login = useRef();
  const [isShown, setIsShown] = useState(false);
  const [worksIsShown, setWorksIsShown] = useState(false);
  const [showBtn, setShowBtn] = useState(false);


  const handleClick = () => {
    if(homeNav.current.classList.contains('mainNavHidden')) {
      homeNav.current.classList.remove('mainNavHidden');
      homeNav.current.classList.add('mainNavOpen');
    } else {
      homeNav.current.classList.remove('mainNavOpen');
      homeNav.current.classList.add('mainNavHidden');
    }
    if(showBtn === false){
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }

  }

  const handleIsShown = () => {
    if(isShown === false){
      setIsShown(true)
    } else {
      setIsShown(false)
    }
    handleClick()
  }

  return (
  <>
    <div className="w-full mainColor h-20 md:h-28 md:pt-8 sticky top-0 z-50 font">
      <div className="w-full h-20 md:h-28 opacity-80 mainColor absolute top-0"></div>
      <nav className='w-full md:w-7/8 flex flex-wrap md:flex-nowrap justify-around md:p-4 z-10 text-black m-auto font-bold text-xl relative z-50 '>
      <img alt='logo' className='logoImg mr-auto w-40 md:w-60 h-20 object-cover  md:pt-0 md:pl-0 md:ml-8 relative  md:bottom-[29px] z-20' src={mainLogo} />
      <button onClick={handleClick} className="text-white text-3xl ml-auto inline-block md:hidden pr-4"><AiOutlineMenuFold/></button>
        <div ref={homeNav} className="mainNav  mainNavHidden md:flex w-full md:w-3/5 justify-around whiteText pb-3">
          <div>
            <Link className="hover-underline-animation whiteText" to='home' onClick={handleClick}>
              Home
            </Link>
          </div>
          <div 
          // onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} 
          >
            <section onClick={handleIsShown} className="hover-underline-animation whiteText flex text-center justify-center">
              {/* <Link to='guidebooks' >Guidebooks</Link> */}
              <Link to='guidebooks/HostsOwners' >Guidebooks</Link>
              {/* <IoIosArrowDown  className="ml-1 mt-1"/> */}
            </section> 
            
            {isShown === 'true' && 
            // {isShown === true && 
              <div className="absolute md:pt-5 w-[40rem] md:w-fit">
                <div className=" relative right-4 md:right-60 z-20 mainColor border-t-4 flex flex-wrap md:flex-nowrap w-[40rem] md:w-fit guideBookNav text-left pb-2">
                  <article className=" mainColor w-[40rem] md:w-[22rem] border-r-0 md:border-r-2 md:border-l-2 border-[#e7dfc6]">
                    <h3 className="font-bold text-xl md:text-2xl md:underline w-max">SHORT TERM RENTALS</h3>
                    <div>
                      <Link onClick={handleIsShown} to='guidebooks/HostsOwners' className="hover-underline-animation">Hosts/Owners</Link>
                      <Link onClick={handleIsShown} to='guidebooks/PropertyManagers'  className="hover-underline-animation">Property Managers</Link>
                      {/* <Link onClick={handleIsShown} to='guidebooks/bnb'  className="hover-underline-animation">Bed & Breakfasts</Link> */}
                      {/* <Link onClick={handleIsShown} to='guidebooks/Cleaning'  className="hover-underline-Cleaning">Cleaning</Link> */}
                    </div>
                  </article>
                  {/* <article className="mainColor w-[40rem] md:w-[20rem] border-r-0 md:border-r-2 border-[#e7dfc6]">
                    <h3 className="font-bold text-xl md:text-2xl md:underline">OUTDOORS</h3>
                    <div>
                      <Link onClick={handleIsShown} to='guidebooks/Glamping' className="hover-underline-animation">Glamping</Link>
                      <Link onClick={handleIsShown} to='guidebooks/Campgrounds' className="hover-underline-animation">Campgrounds/Campsites</Link>
                      <Link onClick={handleIsShown} to='guidebooks/CamperVans' className="hover-underline-animation">RVs & Camper Vans</Link>
                    </div>
                  </article> */}
                  {/*  <section className="mainColor w-[40rem] md:w-[22rem]">
                    <h3 className="font-bold text-xl md:text-2xl md:underline">OTHER</h3>
                    <div>
                    <Link onClick={handleIsShown} to='guidebooks/Destinations' className="hover-underline-animation">Rental Cars</Link> */}
                      {/* <Link onClick={handleIsShown} to='guidebooks/Events' className="hover-underline-animation">Events</Link>
                      <Link onClick={handleIsShown} to='guidebooks/Destinations' className="hover-underline-animation">Destinations</Link>
                      <Link onClick={handleIsShown} to='guidebooks/Businesses' className="hover-underline-animation">Businesses</Link>
                      <Link onClick={handleIsShown} to='guidebooks/More' className="hover-underline-animation">More Ideas</Link>
                    </div>
                  </section> */}
                </div>
              </div>
            }
          </div>
          <div onMouseEnter={() => setWorksIsShown(true)} onMouseLeave={() => setWorksIsShown(false)}> 
            <Link className="hover-underline-animation whiteText flex ml-12 md:ml-0" to='how'  onClick={handleClick}>
              How It Works 
              {/* <IoIosArrowDown className="ml-1"/> */}
            </Link>
            
            {/*{worksIsShown && 
              <div className="absolute pt-5">
                <section className="relative z-20 mainColor border-t-4 text-left p-2 ">
                  <article className="guideBookNav">
                    <Link to='how' className="hover-underline-animation">
                    How It Works
                    </Link>
                  </article>  
                  <article className="guideBookNav">
                    <Link to='guidebooks/Events' className="hover-underline-animation">
                      Watch a Demonstration
                    </Link>
                  </article>
                  <article className="guideBookNav">
                    <Link to='examples' className="hover-underline-animation">
                      See Examples
                    </Link>
                  </article> 
                </section>
              </div>
            }*/}
          </div>
          <div>
            <Link className="hover-underline-animation whiteText" to='examples' onClick={handleClick}>
              Examples
            </Link>
          </div>
          <div>
            <HashLink className="hover-underline-animation whiteText" to="/#pricing" onClick={handleClick}>
              Pricing
            </HashLink>
          </div>
          <div className="">
            <Link className="hover-underline-animation whiteText" to='about' onClick={handleClick}>
              About Us
            </Link>
          </div>
          
        </div>
        <div ref={login} className={showBtn === false ? "md:ml-auto relative bottom-2 hidden md:block" : "md:ml-auto relative bottom-2 md:block "}>
          <Link className="bg-white homeText px-3 py-2 rounded-md  border-2" to="/login" onClick={handleClick}>
            Login/Signup 
          </Link>
        </div>
      </nav>
    </div>
    {/* <div className="mainColor w-screen h-28 absolute top-0"></div> */}
  </>
  );
}

export default Nav;