import React, { useContext, useRef } from "react";
import { GuideContext } from "../../../pages/guide";
import { BsHouse, BsInfoCircle, BsMap } from 'react-icons/bs';
import { useStoreContext } from "../../../utils/GlobalState";

function GuideNav() {
  const [state] = useStoreContext();
  const navColor = state?.guide?.colorOne || '#3A3B3c';

  const { guideComponent, setGuideComponent } = useContext(GuideContext);
  const home = useRef();
  const guide = useRef();
  const map = useRef();

  
  function handleNavClick(value) {
    switch(value) {
      case 'guideHome':
        guide.current.classList.remove('active');
        map.current.classList.remove('active');
        home.current.classList.add('active');
        setGuideComponent('guideHome');
        return;
      case 'guideInfo':
        guide.current.classList.add('active');
        map.current.classList.remove('active');
        home.current.classList.remove('active');
        setGuideComponent('guideInfo');
        return;
      case 'guideMap':
        guide.current.classList.remove('active');
        map.current.classList.add('active');
        home.current.classList.remove('active');
        setGuideComponent('guideMap');
        return;
    }
  }

  return(
    <div className='fixed
    inset-x-0
    bottom-0 z-[100]'>
      <div className="border-t-2 border-gray-100 navigation fixed w-full bottom-0 py-2 pb-3 h-24" style={{ backgroundColor: navColor }}> 
        <ul className="">
            <li className="list active" ref={home}>
              <button className="" onClick={() => handleNavClick('guideHome')}>
                <span className="icon"><BsHouse className="text-3xl" /></span>
                <span className="text  text-shadow">Home</span>
              </button>
            </li>
            <div className='navBreak w-[5px] relative z-[1100]'></div>
            <li className="list w-[320%] correctWidth" ref={guide}>
              <button className="" onClick={() => handleNavClick('guideInfo')}>
                <span className="icon"><BsInfoCircle className="text-3xl" /></span>
                <span className="text text-shadow">GUIDE</span>
              </button>
            </li>
            <div className='navBreak w-[5px] relative z-50'></div>
            <li className="list" ref={map}>
              <button className="" onClick={() => handleNavClick('guideMap')}>
                <span className="icon"><BsMap className="text-3xl" /></span>
                <span className="text  text-shadow">MAP</span>
              </button>
            </li>
            <div className="indicator" id="indicator"></div>
        </ul>
      </div>
    </div>
  )
}

export default GuideNav;