import React,{ useEffect, useState, useRef } from "react";
import { UPDATED_CATEGORY } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import { AiOutlineCar } from 'react-icons/ai'
import { BsWifi, BsHouse, BsKey, BsTelephone, BsBagCheck, BsStar, BsPinMap, BsController, BsBook, BsHeart, BsEmojiSmile, BsCart3, BsCurrencyDollar, BsPower, BsListUl, BsMap, BsSignpostSplit } from 'react-icons/bs';
import { FiSettings, FiTrash2, FiEye } from 'react-icons/fi';
import { BiBed, BiPlusMedical } from 'react-icons/bi';
import { GiBinoculars, GiForkKnifeSpoon, GiNightSleep, GiAirplaneDeparture } from 'react-icons/gi';
import { MdPets, MdOutlineWarningAmber } from 'react-icons/md';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RenderModal = (props) => {
  let category = props.category;
  let categoryName = category.name; 
  let categoryDescription = category.description;
  let setActiveModal = props.setActiveModal;
  let guideId = props.guideId;


  const [categoryFormState, setCategoryFormState] = useState({categoryName: categoryName, categoryContent: categoryDescription});
  
  const modal = useRef();
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setCategoryFormState({
      ...categoryFormState,
      [name]: value,
    });
  };
  let [selectedOption, setSelectedOption] = useState(props.category.icon);
  const [updateCategory] = useMutation(UPDATED_CATEGORY);
  const saveModalChanges = async() => {
    // console.log(selectedOption)
    console.log(categoryFormState)
    const updatedCategory = await updateCategory({
      variables: {
        guideId: guideId.guideId,
        categoryId: category._id,
        name: categoryFormState.categoryName,
        description: categoryFormState.categoryContent,
        icon: selectedOption
      }
    });
    console.log(updatedCategory);
    setActiveModal(false)
  };

  const bsWifi = useRef()
  const aiOutlineCar = useRef()
  const bsHouse = useRef()
  const bsKey = useRef()
  const bsTelephone = useRef()
  const bsBagCheck = useRef()
  const bsStar = useRef()
  const bsPinMap = useRef()
  const bsController = useRef()
  const bsBook = useRef()
  const bsHeart = useRef()
  const bsEmojiSmile = useRef()
  const bsCart3 = useRef()
  const bsCurrencyDollar = useRef()
  const bsPower = useRef()
  const bsListUl = useRef()
  const bsMap = useRef()
  const bsSignpostSplit = useRef()
  const fiSettings = useRef()
  const fiTrash2 = useRef()
  const fiEye = useRef()
  const biBed = useRef()
  const biPlusMedical = useRef()
  const giBinoculars = useRef()
  const giForkKnifeSpoon = useRef()
  const giNightSleep = useRef()
  const giAirplaneDeparture = useRef()
  const mdPets = useRef()
  const mdOutlineWarningAmber = useRef()

  useEffect(() => {
    let icon = props.category.icon.toString();
    console.log(icon)
    switch(icon){
      case 'BsHouse':
        console.log('house')
        bsHouse.current.children[0].classList.add('border-4');
        bsHouse.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsWifi':
        console.log('widi')
        bsWifi.current.children[0].classList.add('border-4');
        bsWifi.current.children[0].classList.add('border-cyan-600');
        break;
      
      case 'BsKey':
        bsKey.current.children[0].classList.add('border-4');
        bsKey.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsTelephone':
        bsTelephone.current.children[0].classList.add('border-4');
        bsTelephone.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsBagCheck':
        bsBagCheck.current.children[0].classList.add('border-4');
        bsBagCheck.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsStar':
        bsStar.current.children[0].classList.add('border-4');
        bsStar.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsPinMap':
        console.log('pinnn')
        bsPinMap.current.children[0].classList.add('border-4');
        bsPinMap.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsController':
        bsController.current.children[0].classList.add('border-4');
        bsController.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsBook':
        bsBook.current.children[0].classList.add('border-4');
        bsBook.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsHeart':
        bsHeart.current.children[0].classList.add('border-4');
        bsHeart.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsEmojiSmile':
        bsEmojiSmile.current.children[0].classList.add('border-4');
        bsEmojiSmile.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsCart3':
        bsCart3.current.children[0].classList.add('border-4');
        bsCart3.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsCurrencyDollar':
        bsCurrencyDollar.current.children[0].classList.add('border-4');
        bsCurrencyDollar.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsPower':
        bsPower.current.children[0].classList.add('border-4');
        bsPower.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsListUl':
        bsListUl.current.children[0].classList.add('border-4');
        bsListUl.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsMap':
        bsMap.current.children[0].classList.add('border-4');
        bsMap.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BsSignpostSplit':
        bsSignpostSplit.current.children[0].classList.add('border-4');
        bsSignpostSplit.current.children[0].classList.add('border-cyan-600');
        break;
      case 'FiSettings':
        fiSettings.current.children[0].classList.add('border-4');
        fiSettings.current.children[0].classList.add('border-cyan-600');
        break;
      case 'FiTrash2':
        fiTrash2.current.children[0].classList.add('border-4');
        fiTrash2.current.children[0].classList.add('border-cyan-600');
        break;
      case 'AiOutlineCar':
        aiOutlineCar.current.children[0].classList.add('border-4');
        aiOutlineCar.current.children[0].classList.add('border-cyan-600');
      case 'FiEye':
        fiEye.current.children[0].classList.add('border-4');
        fiEye.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BiBed':
        biBed.current.children[0].classList.add('border-4');
        biBed.current.children[0].classList.add('border-cyan-600');
        break;
      case 'BiPlusMedical':
        biPlusMedical.current.children[0].classList.add('border-4');
        biPlusMedical.current.children[0].classList.add('border-cyan-600');
        break;
      case 'GiBinoculars':
        giBinoculars.current.children[0].classList.add('border-4');
        giBinoculars.current.children[0].classList.add('border-cyan-600');
        break;
      case 'GiForkKnifeSpoon':
        giForkKnifeSpoon.current.children[0].classList.add('border-4');
        giForkKnifeSpoon.current.children[0].classList.add('border-cyan-600');
        break;
      case 'GiNightSleep':
        giNightSleep.current.children[0].classList.add('border-4');
        giNightSleep.current.children[0].classList.add('border-cyan-600');
        break;
      case 'GiAirplaneDeparture':
        giAirplaneDeparture.current.children[0].classList.add('border-4');
        giAirplaneDeparture.current.children[0].classList.add('border-cyan-600');
        break;
      case 'MdPets':
        mdPets.current.children[0].classList.add('border-4');
        mdPets.current.children[0].classList.add('border-cyan-600');
        break;
      case 'MdOutlineWarningAmber':
        mdOutlineWarningAmber.current.children[0].classList.add('border-4');
        mdOutlineWarningAmber.current.children[0].classList.add('border-cyan-600');
        break;
      default:
        break;
    }
  }, [props.category.icon]);

  

  function handleIconChange(e, icon){
    setSelectedOption(icon)

    bsWifi.current.children[0].classList.remove('border-4')
    aiOutlineCar.current.children[0].classList.remove('border-4')
    bsHouse.current.children[0].classList.remove('border-4')
    bsKey.current.children[0].classList.remove('border-4')
    bsTelephone.current.children[0].classList.remove('border-4')
    bsBagCheck.current.children[0].classList.remove('border-4')
    bsStar.current.children[0].classList.remove('border-4')
    bsPinMap.current.children[0].classList.remove('border-4')
    bsController.current.children[0].classList.remove('border-4')
    bsBook.current.children[0].classList.remove('border-4')
    bsHeart.current.children[0].classList.remove('border-4')
    bsEmojiSmile.current.children[0].classList.remove('border-4')
    bsCart3.current.children[0].classList.remove('border-4')
    bsCurrencyDollar.current.children[0].classList.remove('border-4')
    bsPower.current.children[0].classList.remove('border-4')
    bsListUl.current.children[0].classList.remove('border-4')
    bsMap.current.children[0].classList.remove('border-4')
    bsSignpostSplit.current.children[0].classList.remove('border-4')
    fiSettings.current.children[0].classList.remove('border-4')
    fiTrash2.current.children[0].classList.remove('border-4')
    fiEye.current.children[0].classList.remove('border-4')
    biBed.current.children[0].classList.remove('border-4')
    biPlusMedical.current.children[0].classList.remove('border-4')
    giBinoculars.current.children[0].classList.remove('border-4')
    giForkKnifeSpoon.current.children[0].classList.remove('border-4')
    giNightSleep.current.children[0].classList.remove('border-4')
    giAirplaneDeparture.current.children[0].classList.remove('border-4')
    mdPets.current.children[0].classList.remove('border-4')
    mdOutlineWarningAmber.current.children[0].classList.remove('border-4')


    e.currentTarget.children[0].classList.add('border-4')
    e.currentTarget.children[0].classList.add('border-cyan-600')
    
  }

  const [value, setValue] = useState(category.description);
  const handleQuill = (e) => {
    setValue(e)
    console.log(e)
    setCategoryFormState({
      ...categoryFormState,
      categoryContent: e
    });
  }


  return(
    <div ref={modal} data-id={category._id} className="absolute w-screen h-screen m-auto top-0 left-0 z-20">
      <div className="bg-gray-600 opacity-30 w-screen h-screen fixed z-20"></div>
      <div className="fixed top-20 z-30 w-full ">
        <div className="w-11/12 md:w-1/2 m-auto bg-white relative z-100 rounded-md h-[70vh] md:h-[80vh]" >

            <div className="flex justify-between bg-gray-100 pt-4 border-b-2 rounded-t-lg">

              <p className="mx-12 font-bold text-2xl">Edit Section</p>
            <button onClick={() => setActiveModal(false)} className='font-bold text-2xl p-2 px-3 rounded-md text-black z-40 mx-4 hover:shadow-xl'>X</button> 
            </div>
            
            <section className="overflow-y-scroll max-h-[80%]">
            <h2 className="p-1 m-1 w-1/3 font-bold">Section Title: </h2>
            <textarea className="text-black p-1 m-2 w-5/6 rounded-md border-2 border-gray-400" type='text' defaultValue={category.name} onChange={handleFormChange} name="categoryName" id="categoty-name"/>
          <div className="">
            <h2 className="p-1 m-1 w-1/3 font-bold">Section Description: </h2>
            {/* <textarea className="text-black p-1 m-2 w-5/6 h-20 rounded-md border-2 border-gray-400" type='text' defaultValue={category.description} onChange={handleFormChange} name="categoryContent" id="categoty-name"/> */}
            <ReactQuill theme="snow" value={value} onChange={handleQuill} name="description" className='w-4/5 m-auto'/>
          </div>

          <div className="flex flex-wrap justify-around w-full">
            <div className="w-full">
            <p className="w-fit m-auto mt-2">Select an Icon for your Section: </p>
            </div>
            <div className="flex flex-wrap justify-around w-fit m-2">
              
              <button ref={bsWifi} onClick={(e) => handleIconChange(e,'BsWifi')} className="w-[90px] h-[90px]">
                <BsWifi id="BsWifi" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={aiOutlineCar} onClick={(e) => handleIconChange(e,'AiOutlineCar')} className="w-[90px] h-[90px]">
                <AiOutlineCar id="AiOutlineCar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsHouse} onClick={(e) => handleIconChange(e,'BsHouse')} className="w-[90px] h-[90px]">
                <BsHouse id="BsHouse" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsKey} onClick={(e) => handleIconChange(e,'BsKey')} className="w-[90px] h-[90px]">
                <BsKey id="BsKey" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsTelephone} onClick={(e) => handleIconChange(e,'BsTelephone')} className="w-[90px] h-[90px]">
                <BsTelephone id="BsTelephone" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsBagCheck} onClick={(e) => handleIconChange(e,'BsBagCheck')} className="w-[90px] h-[90px]">
                <BsBagCheck id="BsBagCheck" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsStar} onClick={(e) => handleIconChange(e,'BsStar')} className="w-[90px] h-[90px]">
                <BsStar id="BsStar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsPinMap} onClick={(e) => handleIconChange(e,'BsPinMap')} className="w-[90px] h-[90px]">
                <BsPinMap id="BsPinMap" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsController} onClick={(e) => handleIconChange(e,'BsController')} className="w-[90px] h-[90px]">
                <BsController id="BsController" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsBook} onClick={(e) => handleIconChange(e,'BsBook')} className="w-[90px] h-[90px]">
                <BsBook id="BsBook" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsHeart} onClick={(e) => handleIconChange(e,'BsHeart')} className="w-[90px] h-[90px]">
                <BsHeart id="BsHeart" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsEmojiSmile} onClick={(e) => handleIconChange(e,'BsEmojiSmile')} className="w-[90px] h-[90px]">
                <BsEmojiSmile id="BsEmojiSmile" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={giAirplaneDeparture} onClick={(e) => handleIconChange(e,'GiAirplaneDeparture')} className="w-[90px] h-[90px]">
                <GiAirplaneDeparture id="GiAirplaneDeparture" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsCart3} onClick={(e) => handleIconChange(e,'BsCart3')} className="w-[90px] h-[90px]">
                <BsCart3 id="BsCart3" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsCurrencyDollar} onClick={(e) => handleIconChange(e,'BsCurrencyDollar')} className="w-[90px] h-[90px]">
                <BsCurrencyDollar id="BsCurrencyDollar" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsListUl} onClick={(e) => handleIconChange(e,'BsListUl')} className="w-[90px] h-[90px]">
                <BsListUl id="BsListUl" className="text-7xl m-auto  p-1 rounded-lg"/>
              </button>
              <button ref={bsPower} onClick={(e) => handleIconChange(e,'BsPower')} className="w-[90px] h-[90px]">
                <BsPower id="BsPower" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsMap} onClick={(e) => handleIconChange(e,'BsMap')} className="w-[90px] h-[90px]">
                <BsMap id="BsMap" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={bsSignpostSplit} onClick={(e) => handleIconChange(e,'BsSignpostSplit')} className="w-[90px] h-[90px]">
                <BsSignpostSplit id="BsSignpostSplit" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiSettings} onClick={(e) => handleIconChange(e,'FiSettings')} className="w-[90px] h-[90px]">
                <FiSettings id="FiSettings" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiTrash2} onClick={(e) => handleIconChange(e,'FiTrash2')} className="w-[90px] h-[90px]">
                <FiTrash2 id="FiTrash2" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={fiEye} onClick={(e) => handleIconChange(e,'FiEye')} className="w-[90px] h-[90px]">
                <FiEye id="FiEye" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={biBed} onClick={(e) => handleIconChange(e,'BiBed')} className="w-[90px] h-[90px]">
                <BiBed id="BiBed" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={biPlusMedical} onClick={(e) => handleIconChange(e,'BiPlusMedical')} className="w-[90px] h-[90px]">
                <BiPlusMedical id="BiPlusMedical" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giBinoculars} onClick={(e) => handleIconChange(e,'GiBinoculars')} className="w-[90px] h-[90px]">
                <GiBinoculars id="GiBinoculars" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giForkKnifeSpoon} onClick={(e) => handleIconChange(e,'GiForkKnifeSpoon')} className="w-[90px] h-[90px]">
                <GiForkKnifeSpoon id="GiForkKnifeSpoon" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={giNightSleep} onClick={(e) => handleIconChange(e,'GiNightSleep')} className="w-[90px] h-[90px]">
                <GiNightSleep id="GiNightSleep" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={mdPets} onClick={(e) => handleIconChange(e,'MdPets')} className="w-[90px] h-[90px]">
                <MdPets id="MdPets" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>
              <button ref={mdOutlineWarningAmber} onClick={(e) => handleIconChange(e,'MdOutlineWarningAmber')} className="w-[90px] h-[90px]">
                <MdOutlineWarningAmber id="MdOutlineWarningAmber" className="text-7xl m-auto p-1 rounded-lg"/>
              </button>              
              {/* <Select
            name="icon"
            className="text-black w-full "
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            /> */}
            </div>
            

          </div>

          </section>
          <div className="border-t-2 py-2">
          <button className='mainColor text-white font-bold text-xl py-2 px-4 m-2 rounded mb-4 hover:shadow-xl' onClick={saveModalChanges}>Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderModal