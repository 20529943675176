import React from "react";
import QRCode from "react-qr-code";

const QRCodeModal = (props) => {
  const setDisplay = props.setDisplay;
  if(props.display == 'none') {
    return;
  }

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qrCode");
    var svgString = document.getElementById("qrCode").outerHTML
    var dataUrl = 'data:image/svg+xml,'+encodeURIComponent(svgString);
    let downloadLink = document.createElement("a");
    downloadLink.href = dataUrl;
    downloadLink.download = `${props.guide.name}QRCode.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className={props.display == 'none' ? "hidden" : "fixed bottom-0 left-0 w-full h-screen"}>
      <div className="bg-gray-700 fixed top-0 w-full h-screen opacity-40 z-0 overflow-hidden">
      </div>
      <div className="fixed top-40 w-screen">
      <div className="border-2 bg-white homeText  z-20 w-11/12 md:w-1/3 m-auto rounded-lg">
        <div  className="font-bold bg-gray-100 border-b-2 mb-8 p-4 pt-5 text-2xl md:text-3xl flex justify-between px-4md:px-8">
          <p>{props.guide.name} QR Code</p>
          <button onClick={() => setDisplay('none')}>X</button>
        </div>
      
          <div className="w-2/3 flex justify-around m-auto">
            <div className="p-2 bg-white">
              <QRCode id="qrCode" className="" value={props.url} />
            </div>
            
          </div>
          <button onClick={downloadQRCode} className="my-6 p-2 px-4 pt-3 mainColor text-white font-bold rounded-md" >Download QR Code</button>
      </div>
      </div>
    </div>
    
    
  )
}

export default QRCodeModal;