import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from 'react-icons/io';
import { BsInstagram, BsTwitter, BsFacebook } from 'react-icons/bs'
function Footer() {
 
  return(
    <div className="sticky bottom-0 pt-8 secondColor homeText text-xl font-bold flex flex-wrap font">
      <div className="flex flex-wrap items-start w-2/5 md:w-1/4 mr-auto pl-3 md:pl-24 text-sm md:text-lg">
        <Link className="w-full flex my-2 mr-auto" to='guidebooks'><IoIosArrowForward className="relative top-1"/>Guidebooks</Link>
        <Link className="w-full flex my-2 mr-auto" to='how'><IoIosArrowForward className="relative top-1"/>How It Works</Link>
        <Link className="w-full flex my-2 mr-auto" to='examples'><IoIosArrowForward className="relative top-1"/>Examples</Link>
        <Link className="w-full flex my-2 mr-auto" to='contact'><IoIosArrowForward className="relative top-1"/>About Us</Link>
        <Link className="w-full flex my-2 mr-auto" to='login'><IoIosArrowForward className="relative top-1"/>Login/Signup</Link>
      </div>
      <div className="flex flex-wrap items-start w-3/5 md:w-1/4 ml-auto pr-3 md:mr-12 pr-2 text-right">
        <h4 className="text-sm md:text-md ml-auto">Have questions?</h4>
        <p className="text-sm hidden lg:flex md:text-2xl font-medium">Reach out to us at contact@vacanzeguides.com!</p>
        <p className="w-full text-sm break-all">contact@vacanzeguides.com</p>
        {/* <div className="ml-auto flex text-md md:text-3xl">
          <BsInstagram />
          <BsTwitter className="mx-8"/>
          <BsFacebook />
        </div> */}
        
      </div>
      <div className="w-full pt-5 text-sm md:text-md">
      <p className="m-auto">© <span className="logoFont">VACANZE</span> 2022   |   All rights reserved   |   Terms & Conditions</p>
      </div>
    </div>
  )
};
export default Footer;