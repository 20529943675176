import React from "react";
import { TbArrowBigLeft } from 'react-icons/tb';
import { IoIosArrowDown } from 'react-icons/io';
const Category = (props) => {


  const openSubCategory = (e) => {
    const button = e.currentTarget.children[2];
    const Section = e.currentTarget.parentNode;
    if(button.classList.contains('rotateButtonDown')){
      button.classList.add('rotateButtonUp')
      button.classList.remove('rotateButtonDown')
    } else {
      button.classList.add('rotateButtonDown')
      button.classList.remove('rotateButtonUp')
    }
    if(Section.classList.contains('subCategoryClosed')){
      Section.classList.add('subCategoryOpen')
      Section.classList.remove('subCategoryClosed')
    } else {
      Section.classList.add('subCategoryClosed')
      Section.classList.remove('subCategoryOpen')
    }
  }

  const category = props.props;
  const setInCategory = props.setInCategory;
  const subCategories = category.subCategories;
  const iconColor = props.iconColor;

  return (
    <div className="min-h-screen text-xl">
      <div className="flex justify-between w-screen h-fit mb-3 pt-3 pb-2" style={{ backgroundColor: iconColor }}>
        <button onClick={() => {setInCategory(false)}} className='text-xl font-bold text-white ml-3 p-3 rounded-sm h-max relative bottom-1'><TbArrowBigLeft className="text-3xl" /></button>
      <h1 className="m-2 text-2xl font-bold text-white h-min px-2">{category?.name}</h1>
      <button className='text-xl font-bold text-white mr-3 px-3 rounded-sm opacity-0'><TbArrowBigLeft className="text-3xl" /></button>
      </div>

      {category?.description.length > 0 && category?.description !== '<p><br></p>' &&(
        <div className="flex justify-center w-screen">
          <div id="subCatdescription" className="text-left bg-white rounded-md w-full p-5" dangerouslySetInnerHTML={{__html: category.description}} />
          {/* <p className="mx-4 p-3 w-full px-4 bg-white rounded-md">{category?.description}</p> */}
        </div>
      )}
      {subCategories.length > 0 && 
        <div className="w-11/12 m-auto mt-4 mb-8">
          {subCategories.map((subCategory, index) => {
            console.log(index)
            return ( 
              <>
              
              <div key={subCategory._id} className='mb-3 subCategoryClosed bg-white rounded-xl subCategory'>
                <header onClick={openSubCategory} className="flex bg-white p-2 pt-3 pb-2 rounded-xl">
                  <div></div>
                  <h1 className="ml-2 pl-1 pr-3 border-b-[1px] border-gray-400 border-solid font-bold mr-auto">{subCategory.name}</h1>
                  <button className="rotateButtonDown">
                    <IoIosArrowDown className="text-2xl text-black" />
                  </button>
                </header>
                
                <article className="">
                {subCategory.picture && (
                    <img className="w-full h-[11rem] object-cover mb-3 rounded" src={subCategory.picture} />
                )}
                <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: subCategory.description}} />
                  {/* <p className="text-left mx-2 text-[18px]">{subCategory.description}</p> */}
                  {subCategory.link && (
                    <a href={subCategory.link} target='_blank' className='text-blue-500 underline text-[18px] mt-1'>
                      {subCategory.linkName ? 
                      (
                        <p>{subCategory.linkName}</p>
                      ) : (
                        <p>{subCategory.link}</p>
                      )}
                    </a>
                  )}
                </article>
              </div>
              </>
            )
          })}
        </div>
      }
    </div>
  )
}

export default Category;