import React, { useState, useRef, useEffect } from 'react';
import loadingVideo from "../../assets/vacanzeguides.mp4";
import logo from '../../assets/icons/dark-png1.png'
import loadGif from '../../assets/loadGif.gif'
import curvyLines from '../../assets/curvyLines.jpg'
// import Placeholder from "react-select/dist/declarations/src/components/Placeholder";


const NoSubscrption = (backgroundPic) => {

  const videoRef = useRef(null);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (autoplay) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [autoplay]);

  return( 
    <div className='md:w-[400px] w-full flex flex-wrap m-auto h-screen align-center' >
      {/* <img src={curvyLines} className="w-screen  h-screen block m-auto absolute fit-cover opacity-[.10]" alt="logo" /> */}
      <div className='w-full mt-auto mb-0 '>
        <img src={logo} className="md:w-[400px] w-4/5 block m-auto relative" alt="logo" />
      </div>
      <div className='w-full  mb-auto'>
        <img src={loadGif} className=" w-[100px] block m-auto relative" alt="lading" /> 
      </div>
      {/* <video src={loadingVideo} autoPlay muted style={{ width: '300px', height: 'auto', display: 'flex', margin: 'auto' }}/> */}
    </div>
)
}

export default NoSubscrption;