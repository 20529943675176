import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_SUBCATEGORY } from '../../../utils/mutations';
import { BsQuestionCircle } from "react-icons/bs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SimpleFileUpload from "react-simple-file-upload";

const AddSubCategory = (props) => {

  const setActiveAddSubCategory = props.setActiveAddSubCategory;
  const category = props.category;
  const guideId = props.guideId;
  const categoryIndex = props.categoryIndex;

  const [formState,setFormState] = useState();

  // react quill

  const [value, setValue] = useState('');

  const [imageState, setImageState] = useState()
  function handleImageFile(url){
    // console.log('The URL of the file is ' + url)
    setImageState(url);
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  
  const [addSubCategory] = useMutation(ADD_SUBCATEGORY); 

  const submitSubCategory = async () => {

    try {
      
      const guide = await addSubCategory({
        variables: {
          guideId: guideId,
          categoryId: category._id,
          categoryIndex: categoryIndex,
          name: formState.name,
          description: value,
          link: formState.link,
          linkName: formState.linkName,
          picture: imageState
        }
        
      })
      setActiveAddSubCategory(false)
    } catch (e) {
      console.log(e)
    }
    
  }
  const handleQuill = (e) => {
    setValue(e)
    console.log(e)
    setFormState({
      ...formState,
      description: value
    });
  }
  console.log(formState)
  

  return (
    // <div className="w-screen h-screen absolute top-0 left-0">
    <>
      <div className="w-screen h-screen opacity-40 bg-gray-500 fixed top-0 left-0">

      </div>
      <div className="fixed top-50 left-50 h-full w-full">
        <div className=" mt-32 top-24 shadow border-2 bg-white w-11/12 md:w-1/3 m-auto rounded-xl font-bold max-h-[75vh] overflow-y-scroll">
          <header className="flex bg-gray-100 border-b-2 justify-between mb-6 rounded-t-xl px-6 py-2 pt-3">
            <h3 className="text-xl">Add Sub-Category to '{category.name}'</h3>
            <button onClick={() => setActiveAddSubCategory(false)} className=" hover:shadow-xl">X</button>
          </header>
          <div className=" overflow-y-scroll max-h-[70vh] md:max-h-[600px]">
          <div className="mb-6">
            <p>Sub-Category Name:</p>
            <input name="name" placeholder='Name' onChange={handleFormChange} className="w-3/4 rounded-lg border-2 px-2"  />
          </div>
          <div>
            <p>Sub-Category Description:</p>
            <ReactQuill theme="snow" value={value} onChange={handleQuill} name="description" placeholder='Description' className='w-4/5 m-auto'/>
            {/* <textarea name="description" placeholder='Description' onChange={handleFormChange} className="w-3/4 rounded-lg border-2 px-2" /> */}
          </div>

          <div className="m-auto w-full mt-6">
            <p>Add Image <span className="text-gray-500 text-sm">(not required)</span></p>
          <div className="w-min m-auto">
            <SimpleFileUpload
              apiKey="b0c9e9b3278499b0b02bced650795a13"
              preview="true"
              onSuccess={handleImageFile}
            />
          </div>
          </div>
          <button onClick={submitSubCategory} className="mainColor text-white font-bold mt-4 p-1 px-4 py-2 mb-6 rounded-lg  hover:shadow-xl">SUBMIT</button>
          </div>
          </div>
          
          </div>
        
        {/* </div> */}
        </>
    
  )
}

export default AddSubCategory;