import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import auth from "../../../utils/auth";
import { AiOutlineMenuFold } from 'react-icons/ai';
import lightLogo from '../../../assets/lightLogoNoBg.png';

function DasboardNav() {
  const [selectedSection, setSelectSection] = useState('guides')
  const dashNav = useRef();

  const handleNavClick = () => {
    console.log(dashNav.current.classList);
    if(dashNav.current.classList.contains('dnavClosed')){
      dashNav.current.classList.remove('dnavClosed');
      dashNav.current.classList.add('dNavOpen');
    } else {
      dashNav.current.classList.add('dnavClosed');
      dashNav.current.classList.remove('dNavOpen');
    }
    
  }

  const handleLogout = () => {
    auth.logout();
  }

  const handleClick = (section) => {
    setSelectSection(section);
    dashNav.current.classList.add('dnavClosed');
    dashNav.current.classList.remove('dNavOpen');
  }

  return (
    <nav className="dashboardNav font"> 
      <section> 
        <Link to="/dashboard" className="text-xl md:text-3xl"><img alt='icon' className="w-16 md:w-24 relative bottom-2 md:bottom-3 mb-[-60px] md:mb-[-20px] my-[-10px]" src={lightLogo} /></Link>
        <p className=" ml-[-30px] sm:hidden inline-block text-white font-bold text-3xl m-auto w-3/4 text-center">VACANZE</p>
        <button className="text-white text-3xl ml-auto inline-block sm:hidden" onClick={handleNavClick}><AiOutlineMenuFold/></button>
        <div ref={dashNav} className="dnavClosed">
          <div className={selectedSection === 'guides' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('guides')} to='guides' >
              Guides
            </Link>
          </div>
          {/* <div className={selectedSection === 'subsription' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('subsription')}  to='subsription' >
              Subscription
            </Link>
          </div> */}
          <div className={selectedSection === 'account' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('account')}  to='account' >
              Account
            </Link>
          </div>
          {/* <div className={selectedSection === 'help' ? ('navUnderline hover-underline-animation') : ("hover-underline-animation")}>
            <Link onClick={() => handleClick('help')}  to='help' >
              Help/Tutorial
            </Link>
          </div> */}

          <div>
            <button onClick={handleLogout} className="flex md:hidden font-bold text-white p-2 mx-5 hover-underline-animation">Log Out</button>
          </div>
        </div>
      </section>

      <div>
        <button onClick={handleLogout} className="hidden md:inline-block font-bold text-white p-2 mx-5 hover-underline-animation">Log Out</button>
      </div>
    </nav>
  );
}

export default DasboardNav;