import React, { useState } from "react";
import { useStoreContext } from "../../../utils/GlobalState";
import { HiOutlineMail,HiOutlinePhone } from 'react-icons/hi'
import { HiOutlineLocationMarker } from 'react-icons/hi'


function GuideHome() {
  const [state] = useStoreContext();

  return(
      <div className="guideHome h-full w-full top-0 left-0 fixed bg-gray-600 text-white guide">
        <div className="guideHomebg h-full w-full">
          <img className="imageCover" src={state?.guide?.photo} />
        </div>
        <div className="guideHomeFront flex flex-col justify-around h-screen pb-20 text-shadow">
          <div className="w-5/6 mx-auto rounded">
            {/* {state?.guide?.logo && (
              <img rel="icon" className="w-1/3 m-auto mb-3 rounded-xl" src={state?.guide?.logo} alt='logo' />
            )} */}
            
            <h1 className="font-medium text-2xl ">Welcome to </h1>
            <strong className="text-3xl font-semibold text-shadow">{state?.guide?.name}</strong>

            <div className="flex justify-around my-3"> 
              <div className="flex border-b-2 pb-3">
                <a href={'tel:' + state?.guide?.contactPhone} className="text-6xl pr-2">
                <div className="border-2 rounded-full p-2" >
                  <HiOutlinePhone className="text-4xl"/>
                </div>
                </a>
                <a href={'mailto:' + state?.user?.email}>
                <div className="border-2 rounded-full p-2" > <HiOutlineMail className="text-4xl"/> </div>
                </a>
              </div>
            </div>
            <div className="flex justify-around">
            <a className='w-4/5 md:w-2/3 font-bold text-xl font-normal flex m-auto' href={'https://maps.google.com/?q=' + state?.guide?.address} target='_blank'>
              <p className="m-auto flex text-center"><HiOutlineLocationMarker className="text-4xl relative bottom-1"/>{state?.guide?.address}</p>
            </a>
            
            </div>
            {/* <HiOutlineLocationMarker className="mt-1 m-auto md:w-auto text-5xl w-fit"/> */}
          </div>
          
        </div>
      </div>

  )
}

export default GuideHome;