import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        email
        username
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($username: String!, $email: String!, $password: String!, $subscriptionStatus: String!) {
    addUser(username: $username, email: $email, password: $password, subscriptionStatus: $subscriptionStatus) {
      token
      user{
        _id
        email
        username
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID) {
    deleteUser(userId: $userId){
      email
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($userId: ID, $changePasswordId: String, $password: String) {
    updateUserPassword(userId: $userId, changePasswordId: $changePasswordId, password: $password){
        email
        username
    }
  }
`;

export const ADD_GUIDE = gql`
  mutation addGuide($name: String!, $address: String!, $photo: String, $logo: String $contactPhone: String!) {
    addGuide(name: $name, address: $address, photo: $photo, logo: $logo, contactPhone: $contactPhone) {
        _id
        name
        address
        photo
        logo
        contactPhone
        colorOne
        colorTwo
    }
  }
`;

export const TRANSFER_GUIDE = gql`
  mutation transferGuide($guideId: ID! $email: String!) {
    transferGuide(guideId: $guideId, email: $email) {
    _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          name
          description
          _id
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
  }
  }
`;

export const UPDATE_GUIDE_TITLE = gql`
  mutation updateGuideTitle($guideId: ID!, $name: String!){
  updateGuideTitle(guideId: $guideId, name: $name) {
    _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          name
          description
          _id
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
  }
}
`;

export const UPDATE_GUIDE_PHONE = gql`
  mutation updateGuidePhone($guideId: ID!, $contactPhone: String!){
    updateGuidePhone(guideId: $guideId, contactPhone: $contactPhone) {
    _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          name
          description
          _id
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
  }
}
`;

export const UPDATE_GUIDE_TITLE_POI = gql`
  mutation updateGuideTitlePoi($guideId: ID!, $oldName: String, $newName: String) {
    updateGuideTitlePoi(guideId: $guideId, oldName: $oldName newName: $newName) {
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          name
          description
          _id
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_GUIDE_ADDRESS = gql`
  mutation updateGuideAddress($guideId: ID!, $address: String!){
    updateGuideAddress(guideId: $guideId, address: $address) {
    _id
    name
    address
    photo
    logo
    contactPhone
    colorOne
    colorTwo
    categories {
      _id
      name
      description
      icon
      order
      subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
    }
  }
}
`;

export const DELETE_GUIDE = gql`
  mutation deleteGuide($guideId: ID!) {
    deleteGuide(guideId: $guideId) {
      _id
      username
      email      
      subscriptionStatus
      guides {
        _id
        name
        address
        photo
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation addCategory($guideId: ID!, $name: String!, $description: String!, $icon: String!, $order: Float!){
  addCategory(guideId: $guideId, name: $name, description: $description, icon: $icon, order: $order) {
    _id
    name
    address
    photo
    logo
    colorOne
    colorTwo
    contactPhone
    categories {
      _id
      name
      description
      icon
      order
      subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
    }
  }
}
`;

export const UPDATED_CATEGORY = gql`
  mutation updateCategory($guideId: ID!, $categoryId: ID! $name: String!, $description: String!, $icon: String!) {
    updateCategory(guideId: $guideId, categoryId: $categoryId name: $name, description: $description, icon: $icon) {
      _id
      name
      address
      photo
      logo
      contactPhone
      colorOne
      colorTwo
      categories {
        _id
        name
        description
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
    }
}
`;

export const UPDATE_CATEGORY_ORDER = gql`
  mutation updateCategoryOrder($guideId: ID!, $categoryId: ID!, $order: Float!) {
    updateCategoryOrder(guideId: $guideId, categoryId: $categoryId, order: $order){
      _id
      name
      address
      photo
      logo
      contactPhone
      colorOne
      colorTwo
      categories {
        _id
        name
        description
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
    }
  }
`;

export const CHANGE_CATEGORY_ORDER = gql`
  mutation changeCategoryOrder($guideId: ID!) {
    changeCategoryOrder(guideId: $guideId){
      _id
      name
      address
      photo
      logo
      contactPhone
      colorOne
      colorTwo
      categories {
        _id
        name
        description
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($guideId: ID!, $categoryId: ID!) {
  deleteCategory(guideId: $guideId, categoryId: $categoryId) {
    _id
    name
    address
    contactPhone
    photo
    logo
    colorOne
    colorTwo
    categories {
      _id
      description
      name
      icon
      order
      subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
    }
  }
}
`;

export const ADD_POI = gql`
  mutation addPoi($guideId: ID!, $name: String, $type: String, $address: String, $description: String, $lat: Float, $lng: Float, $pic: String){
    addPoi(guideId: $guideId, name: $name, type: $type, address: $address, description: $description, lat: $lat, lng: $lng, pic: $pic) {
        _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATED_POI = gql`
  mutation updatePoi($guideId: ID!, $name: String, $newName: String $type: String, $address: String, $description: String, $lat: Float, $lng: Float, $pic: String){
    updatePoi(guideId: $guideId, name: $name, newName: $newName, type: $type, address: $address, description: $description, lat: $lat, lng: $lng, pic: $pic){
        _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`; 

export const DELETE_POI = gql`
  mutation deletePoi($guideId: ID!, $poiId: ID!){
    deletePoi(guideId: $guideId, poiId: $poiId){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_COLOR_ONE = gql`
  mutation updateGuideColorOne($guideId: ID!, $colorOne: String!) {
    updateGuideColorOne(guideId: $guideId, colorOne: $colorOne){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_COLOR_TWO = gql`
  mutation updateGuideColorTwo($guideId: ID!, $colorTwo: String!) {
    updateGuideColorTwo(guideId: $guideId, colorTwo: $colorTwo){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_PHOTO = gql`
  mutation updateGuidePhoto($guideId: ID!, $photo: String!) {
    updateGuidePhoto(guideId: $guideId, photo: $photo){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_LOGO = gql`
  mutation updateGuideLogo($guideId: ID!, $logo: String!) {
    updateGuideLogo(guideId: $guideId, logo: $logo){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const ADD_SUBCATEGORY = gql`
  mutation addSubCategory($guideId: ID, $categoryId: ID, $categoryIndex: Int, $name: String, $description: String, $link: String, $linkName: String, $picture: String) {
    addSubCategory(guideId: $guideId, categoryId: $categoryId, categoryIndex: $categoryIndex, name: $name, description: $description, link: $link, linkName: $linkName, picture: $picture){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_SUBCATEGORY = gql`
  mutation updateSubCategory($guideId: ID, $categoryId: ID, $subCategoryId: ID, $name: String, $description: String, $link: String, $linkName: String, $picture: String) {
    updateSubCategory(guideId: $guideId, categoryId: $categoryId, subCategoryId: $subCategoryId, name: $name, description: $description, link: $link, linkName: $linkName, picture: $picture){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation deleteSubCategory($guideId: ID, $categoryId: ID, $subCategoryId: ID) {
    deleteSubCategory(guideId: $guideId, categoryId: $categoryId, subCategoryId: $subCategoryId){
      _id
      name
      address
      contactPhone
      photo
      logo
      colorOne
      colorTwo
      categories {
        _id
        description
        name
        icon
        order
        subCategories{
          _id
          name
          description
          link
          linkName
          picture
        } 
      }
      poi{
        _id
        name
        type
        address
        description
        lat
        lng
        pic
      }
    }
  }
`;

export const UPDATE_SUB_NAME = gql`
  mutation updateSubName($numberOfGuides: String, $interval: String){
    updateSubName(numberOfGuides: $numberOfGuides, interval: $interval){
      username
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($subId: ID, $userId: ID, $guideNumber: Float, $interval: String){
    updateSubscription(subId: $subId, userId: $userId, guideNumber: $guideNumber, interval: $interval){
        username
        _id
        email
        subscriptionStatus,
        stripeCustId,
        subId,
        subscriptionName,
        subscriptionPrice,
        subscriptionNextPaymentDate,
        subCancelAtEndofPeriod
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($userId: ID){
    cancelSubscription(userId: $userId){
        username
        _id
        email
        subscriptionStatus,
        stripeCustId,
        subId,
        subscriptionName,
        subscriptionPrice,
        subscriptionNextPaymentDate,
        subCancelAtEndofPeriod
        guides {
          _id
          address
          name
          contactPhone
          photo
          logo
          colorOne
          colorTwo
          categories {
            _id
            name
            description
            icon
            order
            subCategories{
              name
              description
              _id
              link
              linkName
              picture
              parentCategory
            }        
          }
      }
    }
  }
  `;