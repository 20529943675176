import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_USER, LOGIN } from '../../utils/mutations';
import Auth from '../../utils/auth';
import loginBg1 from '../../assets/loginBg1.png';
import RLogo from '../../assets/logo.png';
import preview from '../../assets/icons/Dark-preview.png';


function LoginSignup () {

  const [loginToggle, setloginToggle] = useState(false);
  const toggleElement = useRef();
  const background = useRef();
  const form = useRef();
  
  const [accountText, setAccountText] = useState(`Don't Have an accout yet?`)
  const [btnText, setBtnText] = useState(`Click Here to Sign Up`)
  const [welcome, setWelcome] = useState(`Welcome back to Vacanze!`)
  function handleToggle() {
    if(loginToggle === false) {
      toggleElement.current.classList.toggle('toggleContainer');
      toggleElement.current.classList.toggle('toggleContainer2');
      background.current.classList.toggle('toggleChildren');
      form.current.classList.toggle('toggleChildren');
      background.current.children[0].classList.remove('ml-auto');
      background.current.children[0].classList.add('mr-auto');
      signupStuff.current.classList.toggle('hidden');
      loginStuff.current.classList.toggle('hidden');
      setAccountText('Already have an account?')
      setBtnText('Click Here to Login')
      setWelcome('Welcome to Vacanze!')
      setloginToggle(true);
      return;
    }
    if(loginToggle === true) {
      toggleElement.current.classList.toggle('toggleContainer');
      toggleElement.current.classList.toggle('toggleContainer2');
      background.current.classList.toggle('toggleChildren');
      form.current.classList.toggle('toggleChildren');
      background.current.children[0].classList.remove('mr-auto');
      background.current.children[0].classList.add('ml-auto');
      signupStuff.current.classList.toggle('hidden');
      loginStuff.current.classList.toggle('hidden');
      setAccountText(`Don't Have an accout yet?`)
      setBtnText('Click Here to Create an Account')
      setWelcome('Welcome back to Vacanze!')
      setloginToggle(false);
      return;
    }

    return;
  }

  // LOGIN FUNCTIONALITY
  const [loginFormState, setLoginFormState] = useState({
    email: "",
    password: "",
  });
  const [login] = useMutation(LOGIN);

  const handleLoginFormSubmit = async (event) => {
    try {
      console.log('logging in')
      const mutationResponse = await login({
        variables: {
          email: loginFormState.email,
          password: loginFormState.password,
        },
      });
      const token = mutationResponse.data.login.token;
      Auth.login(token);
      if(Auth.loggedIn) {
        window.location.replace("/dashboard");
      }
    } catch (e) {
      loginStuff.current.children[3].classList.add('error')
      loginStuff.current.children[5].classList.add('error');
      loginStuff.current.children[1].classList.remove('opacity-0')
    }
  };

  const handleLoginChange = (event) => {
    loginStuff.current.children[3].classList.remove('error')
    loginStuff.current.children[5].classList.remove('error')
    loginStuff.current.children[1].classList.add('opacity-0')
    const { name, value } = event.target;
    setLoginFormState({
      ...loginFormState,
      [name]: value,
    });
  };

  // send verify email
  const [sent, setSent] = useState(false);
  const handleSendEmail = async (userData) => {
    setSent(true)
    try {
        const sentEmail = await fetch("/sendWelcome", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
      });
    } catch(e) {
      console.log(e);
    }
  }
  //SIGNUP FUNCTIONALITY
  const [signupFormState, setSignupFormState] = useState({
    uname: "",
    eml: "",
    psw: "",
    subscriptionStatus: "false"
  });
  const [addUser] = useMutation(ADD_USER);

  const [errorText, setErrorText] = useState('.')

  const handleSignupFormSubmit = async (event) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(signupFormState.eml)){
    try{
    const mutationResponse = await addUser({
      variables: {
        username: signupFormState.eml,
        email: signupFormState.eml,
        password: signupFormState.psw,
        subscriptionStatus: signupFormState.subscriptionStatus
      },
    });
    // eslint-disable-next-line
    const userData = mutationResponse?.data?.addUser?.user;
    handleSendEmail(userData);
    
    const token = mutationResponse.data.addUser.token;
    Auth.login(token);
    if(Auth.loggedIn) {
      window.location.replace("/dashboard");
    }
    } catch(e){
      // console.log(e)
      let error = e.toString();
      // console.log(error)
      if(error.includes('password:') && error.includes('is shorter than the minimum allowed length')){
        signupStuff.current.children[2].children[5].classList.add('error');
        setErrorText('Password too short , must be at least 7 characters long')
      } else if(error.includes('duplicate key error collection') && error.includes('dup key: { email:')){
        signupStuff.current.children[2].children[3].classList.add('error');
        setErrorText('An account already exists for this email address')
      } else if(error.includes('Path `username` is required.')){
        signupStuff.current.children[2].children[1].classList.add('error');
        setErrorText('Must enter a username')
      } else if(error.includes('Path `email` is required.')){
        signupStuff.current.children[2].children[3].classList.add('error');
        setErrorText('Must enter an email')
      } else if(error.includes('Path `password` is required.')){
        signupStuff.current.children[2].children[5].classList.add('error');
        setErrorText('must enter a password error')
      }
      
      signupStuff.current.children[1].classList.remove('opacity-0');
    }
  } else {
    signupStuff.current.children[1].classList.remove('opacity-0');
    signupStuff.current.children[2].children[1].classList.add('error');
        setErrorText('Must enter a valid email address')
  }
  };
  console.log(errorText)
  const handleSignupChange = (event) => {
    const { name, value } = event.target;
    signupStuff.current.children[1].classList.add('opacity-0');
    signupStuff.current.children[2].children[3].classList.remove('error');
      signupStuff.current.children[2].children[1].classList.remove('error');
      signupStuff.current.children[2].children[5].classList.remove('error');
    setSignupFormState({
      ...signupFormState,
      [name]: value,
    });
    
  };

  //show Passowrd
  const signupPass = useRef();
  const showPassword = () => {
    if(signupPass.current.type === 'password'){
      signupPass.current.type = 'text'
    } else {
      signupPass.current.type = 'password'
    }
  }

  // mobile switch between login and signup
  const loginStuff = useRef();
  const signupStuff = useRef();
  const loginBtn = useRef();
  const signupBtn = useRef();
  const mobileSwitchLogin = () => {
    signupBtn.current.classList.remove('signupHeader')
    loginBtn.current.classList.add('loginHeader')
      loginStuff.current.classList.remove('hidden');
      signupStuff.current.classList.add('hidden');
  }
  const mobileSwitchSignup = (e) => {
    signupBtn.current.classList.add('signupHeader')
    loginBtn.current.classList.remove('loginHeader')
    signupStuff.current.classList.remove('hidden');
    loginStuff.current.classList.add('hidden');
  }

  //forgot password stuff
  const [activeForgotPassword, setActiveForgotPassword] = useState(false);
  const [activeEmailSent, setActiveEmailSent] = useState(false);
  const [accountEmail, setAccountEmail] = useState({ email: '' });
  const handleForgotPasswordChange = (event) => {
    const { name, value } = event.target;
    setAccountEmail({
      ...accountEmail,
      [name]: value,
    });
  };

  const sendPasswordEmail = async () => {
    try {
      // const sentEmail = await fetch("http://localhost:3002/sendPasswordChange", {
        const sentEmail = await fetch("/sendPasswordChange", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(accountEmail) 
      });
      console.log(sentEmail);
      setActiveForgotPassword(false);
      setActiveEmailSent(true);
    } catch(e) {
      console.log(e);
    }
  }
 
  return (
    <>
      <div className='opacity-[1%]'>hi</div>
      <div ref={toggleElement} className='toggleContainer2 w-11/12 md:w-3/5 m-auto h-fit pb-10 md:py-20 relative z-90 absolute flex'>
        <div ref={form} className='flex flex-wrap border-2 guideShadow bg-white left-4 md:left-0 mr-auto rounded-xl w-11/12 md:w-3/5 pb-5 h-fit mt-4 md:mt-28 mb-40 md:mb-0 relative z-10'>
          <div className='w-full flex flex-wrap'>
            <div className='w-full flex justify-around md:pb-0 mb-4 md:mb-0 border-b-2 md:border-b-0 h-min'>
              <button ref={loginBtn} onClick={mobileSwitchLogin} className='text-xl font-bold w-1/2 md:w-full block md:hidden loginHeader md:pointer-events-none p-2 '>Login</button>
              <button ref={signupBtn} onClick={mobileSwitchSignup} className='text-xl font-bold w-1/2 md:w-full  p-2 md:hidden md:h-0  p-2'>Signup</button>
            </div>
            
            
            <div ref={loginStuff} className='w-full px-5 pt-3'>
              <h2 className='font-bold text-4xl mb-[-2px]'>Login</h2>
              <p className='text-red-500 font-bold opacity-0 mb-[-13px]'>(Email or Password is incorrect)</p>
              <p className='w-full text-start'>Email</p>
              <input type="text" placeholder="Enter Email" name="email" onChange={handleLoginChange} className='w-full p-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300'/>

              <p className='w-full text-start mt-2'>Password</p>
              <input type="password" placeholder="Enter Password" name="password" onChange={handleLoginChange} className='w-full p-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300'/>
              <div className='w-full mt-1'>
                <div className='w-full flex justify-between'>
                  <button onClick={() => (setActiveForgotPassword(true))} className='text-left w-full text-sm text-blue-700 font-bold underline cursor-pointer'>Forgot your password?</button>
                </div>
                
              </div>

              <button type="submit" className='w-1/2 md:w-auto text-white bg-gradient-to-r from-cyan-900 via-cyan-700 to-cyan-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-800 font-bold text-lg rounded-lg px-5 py-2.5 text-center mt-4' onClick={handleLoginFormSubmit}>Login</button>
            </div>
          </div>
          <div ref={signupStuff} className='hidden w-full px-5'>
            <h1 className='md:block font-bold text-xl py-2 pt-3 mb-[-12px] text-4xl'>Signup</h1>
            <p className='opacity-0 mb-[-2px] font-bold text-red-600'>({errorText})</p>
            <div>
            {/* <p className='w-full text-start'>Username</p>
              <input type="text" placeholder="Enter Username" name="uname" onChange={handleSignupChange} className='w-full p-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300'/> */}

              <p className='w-full text-start mt-2'>Email</p>
              <input type="text" placeholder="Enter Email" name="eml" onChange={handleSignupChange} className='w-full p-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300'/>

              <p className='w-full text-start'>Password</p>
              <input ref={signupPass} type="password" placeholder="Enter Password" name="psw" onChange={handleSignupChange} className='w-full p-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300'/>

              <div className="flex items-center mb-4">
                  <input id="showPass" type="checkbox" onClick={showPassword} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 hover:cursor-pointer"/>
                  <label className="ml-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-900">Show Password</label>
              </div>

              <button type="submit" className='w-11/12 md:w-auto text-white bg-gradient-to-r from-cyan-900 via-cyan-700 to-cyan-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-800 font-bold rounded-lg text-lg px-5 py-2 text-center' onClick={handleSignupFormSubmit}>Create Account</button>
            </div>
          </div>
      </div>
      <div ref={background} className='loginBg hidden md:block h-fit relative rounded-xl' 
      // style={{ backgroundImage: `url(${loginBg1})`,
      style={{ backgroundImage: `url(${loginBg1})`,
      backgroundSize: 'cover', fontWeight: '900' }}>
          <section className='w-0 md:w-fit h-96 rounded-xl ml-auto mx-1 p-5 text-white text-3xl mt-12 mb-12'>
          {/* <img className='w-40 m-auto mb-12 bg-white' src={RLogo} alt='logo' /> */}
          {/* <img className='w-40 m-auto mb-12 bg-white' src={preview} alt='logo' /> */}
            <h2 className='text-shadow font-bold mt-24'>{welcome}</h2>
            
            <p className='mt-4 text-shadow font-bold'>{accountText}</p>
            <button onClick={handleToggle} className='mb-auto bg-gradient-to-r bg-white homeText shadow-xl px-3 py-3 rounded-md mt-6 text-2xl font-bold' >{btnText}</button>
          </section>
      </div>
      </div>
      {activeForgotPassword === true && 
        <div className='w-screen h-screen absolute top-0 z-50'>
          <div onClick={() => (setActiveForgotPassword(false))} className='w-screen h-screen absolute top-0 z-60 bg-gray-700 opacity-30'>
          <p></p>
          </div>
          <div className='absolute top-48 w-screen h-screen'>
            <div className='w-4/5 md:w-1/2 m-auto bg-white z-90 opactiy-1 py-4 rounded-lg'>
              <div className='flex justify-between mr-6'>
              <button className='font-bold text-xl opacity-0'>X</button>
                <h1 className='font-bold'>Forgot your password?</h1>
                <button  onClick={() => (setActiveForgotPassword(false))}  className='font-bold text-xl'>X</button>
              </div>
              <p className='px-3 mt-3 md:px-0 md:mt-0'>enter your account's email address here to recieve an email with instructions to change your password.</p>
              <input onChange={handleForgotPasswordChange} name='email' className='border-2 w-4/5 md:w-2/3 mb-3 p-1 pt-2 px-2 rounded-md shadow mt-2' placeholder='Email Address:'/>
              <button onClick={sendPasswordEmail} className='w-2/3 md:w-auto ml-2 mainColor text-white font-bold text-xl p-1 px-2 pt-2 rounded-md'>Submit</button>
            </div>
            
          </div>
  
          
        </div>
      }
      {activeEmailSent === true &&
        <div className='w-screen h-screen absolute top-0 z-50'>
        <div onClick={() => (setActiveEmailSent(false))} className='w-screen h-screen absolute top-0 z-60 bg-gray-700 opacity-30'>
        <p></p>
        </div>
        <div className='absolute top-48 w-screen h-screen'>
          <div className='w-4/5 md:w-[600px] m-auto bg-white z-90 opactiy-1 py-4 pt-0 rounded-lg'>
            <div className='flex justify-between p-6 pb-2'>
              <h1 className='font-bold'>If you have an account, an email has been sent to your inbox to change your password.</h1>
            </div>
            <p className='px-3 mb-3 md:px-0 md:mt-0'>Go to your email inbox to update your password.</p>
            <button onClick={() => (setActiveEmailSent(false))} className='w-2/3 md:w-auto ml-2 mainColor text-white font-bold text-xl p-1 px-8 pt-1 rounded-md'>Close</button>
          </div>
          
        </div>

        
      </div>
      }
    </>

  )
}

export default LoginSignup;