import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { QUERY_CLIENT_USER, QUERY_GUIDE } from '../utils/queries';
import { SET_USER, SET_GUIDE } from '../utils/actions';
import beach from '../assets/icons/Beach-House.png';
import Appartement from '../assets/icons/Appartement.png';
import Cabin from '../assets/icons/Cabin.png';
import RV from '../assets/icons/RV.png';
import Van from '../assets/icons/Sprinter-Van.png';
import light from '../assets/icons/light.png';
import light2 from '../assets/icons/light512.png';
import dark from '../assets/icons/dark.png';
import dark2 from '../assets/icons/dark512.png';
import { Helmet } from 'react-helmet';

import NoSubscrption from '../components/guide/noSubscription';

import GuideNav from '../components/guide/guideNav';
import GuideHome from '../components/guide/guideHome';
import GuideInfo from '../components/guide/guideInfo';
import GuideMap from '../components/guide/guideMap';

import SaveToHome from '../components/guide/saveToHome/saveToHome';

// splashScreenStuff
import withSplashScreen from '../components/guide/splashScreen/splashScreen';

import { useStoreContext } from '../utils/GlobalState';
import { IconContext } from 'react-icons/lib';

export const GuideContext = React.createContext();



function Guide() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
    });
  }, []);


  const [state, dispatch] = useStoreContext();
  const { userId, guideId } = useParams(); 


  //query user to check if subsription is active
  const { loading: loadingUser, data: dataUser } = useQuery(QUERY_CLIENT_USER, {
    variables: {
      userId: userId
    }
  });

  const { loading: loadingGuide, data: dataGuide } = useQuery(QUERY_GUIDE, {
    variables: {
      guideId: guideId
    }
  });

  useEffect(() => {
    if (dataUser) {
      // const delay = setTimeout(() => {
        dispatch({
          type: SET_USER,
          user: dataUser.clientUser,
        });
      // }, 5000);
    }
  }, [dataUser, loadingUser, dispatch]);

  useEffect(() => {
    if (dataGuide) {
      dispatch({
        type: SET_GUIDE,
        guide: dataGuide.guide,
      });


      document.title = dataGuide.guide.name;
      const icon = dataGuide.guide.logo;
      console.log(icon)
      let iconImage;
      switch(icon){
        case 'beach':
          console.log('beach pic')
          // iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/MEhW5OfgpdZ8y-tCXR84XQ9lqmhO4XG7SmP2uN5FID4/plain/s3://static.files-simplefileupload.com/xg23n4nih3lfsyyysbpyw5n3blax';
          iconImage = "https://cdn-ek1u0pq3.resize-files-simplefileupload.com/beuRNHmfW7Lx25huvXwzgpBZzFfcnK8_8l8grp_TL1A/plain/s3://static.files-simplefileupload.com/ke4pks9ycuo5y7gtkpmdmgwrp56h";
          break;
        case 'cabin':
          // iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/lAYtPtq6Y9umjznSjr9stjJKJHOGc7J0umN67XAiHK4/plain/s3://static.files-simplefileupload.com/s3ulwgcal98r5afume0h8ni9lrm1';
          iconImage = "https://cdn-ek1u0pq3.resize-files-simplefileupload.com/gcNTvW_loRZxMGWhlYsJE72zvJzDtxf7CIQPRWayy60/plain/s3://static.files-simplefileupload.com/6sh7pzcbhrzaycpaq9saajnmu9q4";
          break;
        case 'apartment':
          // iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/2HZdQu1lIJaNNACcVpPSuYHEdmSe4fsr0jo979hBr9A/plain/s3://static.files-simplefileupload.com/tqs5xbnziwms9qgno7au61r5pugb';
          iconImage ="https://cdn-ek1u0pq3.resize-files-simplefileupload.com/M9pzZ8D1Ii3ty9OPcddh65X4EjH_n6VPtz-fSMpd7Uc/plain/s3://static.files-simplefileupload.com/4vuxnjuae4t6mwv1q9b6n3k833yt";
          break;
        case 'rv':
          // iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/1BBMQnDevJUKiFfSucHRXMEBk0gL2qQhBufwVxLIQ_8/plain/s3://static.files-simplefileupload.com/8m25tzi1tcq8ekq27jcom097sids';
          iconImage = "https://cdn-ek1u0pq3.resize-files-simplefileupload.com/glrWhYyiAjINvkDzi6dkP0iABhBaiMVNa6HTGFmBcTg/plain/s3://static.files-simplefileupload.com/99mv1z5b9ypra27upswe1nxq97ij";
          break;
        case 'van':
          // iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/5Gc6V918UcREFKlodcuu2SD7FGsuIokGN4XW3hm0so8/plain/s3://static.files-simplefileupload.com/84q8yl85lqk89ivniljb50t4qqo8';
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/clL2HDu__OhNtM1Dpjx8h-2vKX1M9_66xuTaF6xn-nQ/plain/s3://static.files-simplefileupload.com/qisg5hffljdu3c6qrl0u6aqpqd11';
          break;
        case 'light2':
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/B2o-ZJ6fF7RAFhwrJ4AK5kAtZN-4mC3oEAjAm0HPvHA/plain/s3://static.files-simplefileupload.com/259s1o43b0g0qc4w4k026tubzose';
          break;
        case 'dark2':
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/Ohhz7MdR7zlM-qjeK9psSpBA_Q9jVHG-LlkLZH8A074/plain/s3://static.files-simplefileupload.com/0xewkjee7529sa9vdjfbi2nyfflr';
          break;
        case 'dark1':
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/16v_LNEtlpL3SoRJxv38jsCGJ10qv8lhB5AcmdEAEuk/plain/s3://static.files-simplefileupload.com/xuh59ywoo2y83n9auicm3t6tsibx';
          break;
        case 'light1':
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/lRy_FWNxDVKa4PXa5KYBiqMlQXEGx1cG36U2lvRtjCo/plain/s3://static.files-simplefileupload.com/az4wogdnc69lojoazr89fjvbjys8';
          break;
        default:
          iconImage = 'https://cdn-ek1u0pq3.resize-files-simplefileupload.com/B2o-ZJ6fF7RAFhwrJ4AK5kAtZN-4mC3oEAjAm0HPvHA/plain/s3://static.files-simplefileupload.com/259s1o43b0g0qc4w4k026tubzose';
          break;
      }
      let color = dataGuide.guide.colorOne;
      // .substring(0, dataGuide.guide.colorOne.length - 2)
      const manifest = {
        "short_name": dataGuide.guide.name,
        "name": dataGuide.guide.name,
        "background_color": color,
        // "background_color": dataGuide.guide.colorOne,"#71afa2"
        "icons": [
          {
            "src": iconImage,
            "sizes": "152x152",
            "type": "image/png",
          },
          {
            "src": iconImage,
            "sizes": "180x180",
            "type": "image/png",
          },
          {
            "src": iconImage,
            "sizes": "192x192",
            "type": "image/png",
          },
          {
            "src": iconImage,
            "sizes": "1024x1024",
            "type": "image/png",
          },
        ],
        "splash_pages": [
          {
            "src": iconImage,
            "sizes": "640x1135",
            "type": "image/png",
            "orientation": "portrait"
          }
        ],
        "start_url": "/",
        "display": "standalone",
        // "theme_color": color,
        "theme_color": dataGuide.guide.colorOne,
      };
      
      // Serialize the manifest as a JSON string

      const manifestURL = window.location.href;
      manifest.start_url = manifestURL;
      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], {type: 'application/javascript'});
      const manifestHref = URL.createObjectURL(blob);
      const manifestTag = document.getElementById('my-manifest-placeholder');
      manifestTag.setAttribute('href', manifestHref);
      // manifestTag.setAttribute('href', JSON.stringify(manifest));

      const manifestString = JSON.stringify(manifestTag);

      // Set the manifest attribute of the html element
      document.querySelector('html').setAttribute('manifest', 'data:application/manifest+json,' + encodeURIComponent(stringManifest));

      var img = new Image();

// Set the source of the image
switch(icon){
  case 'beach':
    img.src = beach;
    break;
  case 'light1':
    img.src = light;
    break;
  case 'dark1':
    img.src = dark;
    break;
  case 'dark2':
    img.src = dark2;
    break;
  case 'light2':
    img.src = light2;
    break;
  case 'van':
    img.src = Van;
    break;
  case 'rv':
    img.src = RV;
    break;
  case 'apartment':
    img.src = Appartement;
    break;
  case 'cabin':
    img.src = Cabin;
    break;
  default:
    img.src = light;
    break;
}

// Wait for the image to load
img.onload = function() {
  // Create a canvas element
  var canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  // Get the canvas context
  var ctx = canvas.getContext('2d');

  // Set the background color
  ctx.fillStyle = dataGuide.guide.colorOne;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the image onto the canvas
  ctx.drawImage(img, 0, 0);

  // Get the data URL of the canvas
  var dataURL = canvas.toDataURL();
  const iconLink = document.querySelector("link[rel='apple-touch-icon']");
  iconLink.setAttribute('href', dataURL);
};
    }
  }, [dataGuide, loadingGuide, dispatch]);
  
  const [guideComponent, setGuideComponent] = useState('guideHome');

  console.log(state?.guide)

  function returnGuideComponent() {
    switch(guideComponent) {
      case 'guideHome':
        return (
          <GuideHome />
        );
      case 'guideInfo':
        return (
          <GuideInfo />
        );
      case 'guideMap':
        return (
            <GuideMap />
        );
      default:
          break;
    }
  };
console.log(state)
return (
  <>
  <Helmet>
    <title>{state?.guide?.name}</title>
    <meta property="og:title" content={state?.guide?.name} />
    {/* <meta property="title" content={state?.guide?.name} /> */}
    <meta property="og:url" content={`https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`} />
    {/* <meta property="url" content={`https://www.vacanzeguides.com/user/${state?.user?._id}/guide/${state?.guide?._id}`} /> */}
    <meta property="og:site_name" content={state?.guide?.name} />
    <meta property="og:locale" content="en_US" />
    {/* <link rel="canonical" href="http://mysite.com" />  */}
    {/* <meta name='og:description' content='Welcome to our digital guidebook!'/> */}
    <meta name='description' content='Welcome to our digital guidebook!'/>
    <meta name="keywords" content="AirBnB, property management, Vacanze, rental, app, Guides, guidebook" />
    <meta property="og:image" content={state?.guide?.photo} />
    {/* <meta property="image" content={state?.guide?.photo} /> */}
  </Helmet> 
  <div>
    {state?.user?.subscriptionStatus === 'active' ? (
      <GuideContext.Provider value={{ guideComponent, setGuideComponent }}>
        <div className='h-screen guide md:overflow-y-hidden '>
            {returnGuideComponent()}
            {guideComponent !== 'guideHome' && 
            <div className="sticky bottom-0 infoNav bg-black opacity-30"></div>
            }
            <GuideNav />
            
            <SaveToHome/>
        </div>
      </GuideContext.Provider>
    ) : (
    <NoSubscrption backgroundPic={state?.guide?.photo}/>
    )}
    </div>
  </>
)
};

//splashScreenStuff
// export default Guide
export default withSplashScreen(Guide);