import React, { useState, useEffect } from "react";
import { AiOutlineCar } from 'react-icons/ai';
import { BsThreeDotsVertical, BsPlusCircle, BsWifi, BsHouse, BsKey, BsTelephone, BsBagCheck, BsStar, BsPinMap, BsController, BsBook, BsHeart, BsEmojiSmile, BsCart3, BsCurrencyDollar, BsPower, BsListUl, BsMap, BsSignpostSplit, BsClock } from 'react-icons/bs';
import { FiSettings, FiTrash2, FiEye } from 'react-icons/fi';
import { BiBed, BiPlusMedical } from 'react-icons/bi';
import { GiBinoculars, GiForkKnifeSpoon, GiNightSleep, GiAirplaneDeparture, GiCoffeeCup } from 'react-icons/gi';
import { MdPets, MdOutlineWarningAmber } from 'react-icons/md';
import { IoMdPaperPlane, IoIosArrowDown } from 'react-icons/io'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { useStoreContext } from '../../../utils/GlobalState';
import Category from "./category";
import Locations from "./locations";
import SharePage from "./sharePage";

function GuideInfo() {
  const [state] = useStoreContext();
  const iconColor = state?.guide?.colorTwo || '#3A3B3c';
  const navColor = state?.guide?.colorOne || '#3A3B3c';

  const [inCategory, setInCategory] = useState(false);
  const [activeCategory, setActiveCategory] = useState(state?.guide?.categories[0]);
  
  useEffect(() => {
    state?.guide?.categories.forEach(category => {
      if(category.order === 1){
        setActiveCategory(category)
      }
    });
  }, []);

  const showCategory = (category) => {
    
    setActiveCategory(category);
    if(window.innerWidth > 770){
      return
    } else {
      setInCategory(true);
      window.scrollTo(0, 0);
      return(
        <Category />
      )
    }
    
  }

  const [inLocations, setInLocations] = useState(false);
  const showLocations = () => {
    setInLocations(true);
  }

  //DISPLAY ICON ON SECITON
  const displayIcon = (icon) => {
    switch (icon) {
      case 'GiCoffeeCup':
        return (
          <div className="w-full flex justify-around">
            <GiCoffeeCup className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsWifi':
        return (
          <div className="w-full flex justify-around">
            <BsWifi className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsHouse':
        return (
          <div className="w-full flex justify-around">
            <BsHouse className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsKey':
        return (
          <div className="w-full flex justify-around">
            <BsKey className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsTelephone':
        return (
          <div className="w-full flex justify-around">
            <BsTelephone className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsBagCheck':
        return (
          <div className="w-full flex justify-around">
            <BsBagCheck className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'AiOutlineCar':
        return (
          <div className="w-full flex justify-around">
            <AiOutlineCar className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsStar':
        return (
          <div className="w-full flex justify-around">
            <BsStar className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsPinMap':
        return (
          <div className="w-full flex justify-around">
            <BsPinMap className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsController':
        return (
          <div className="w-full flex justify-around">
            <BsController className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsBook':
        return (
          <div className="w-full flex justify-around">
            <BsBook className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsHeart':
        return (
          <div className="w-full flex justify-around">
            <BsHeart className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsEmojiSmile':
        return (
          <div className="w-full flex justify-around">
            <BsEmojiSmile className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsCart3':
        return (
          <div className="w-full flex justify-around">
            <BsCart3 className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsCurrencyDollar':
        return (
          <div className="w-full flex justify-around">
            <BsCurrencyDollar className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsPower':
        return (
          <div className="w-full flex justify-around">
            <BsPower className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsListUl':
        return (
          <div className="w-full flex justify-around">
            <BsListUl className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsMap':
        return (
          <div className="w-full flex justify-around">
            <BsMap className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsSignpostSplit':
        return (
          <div className="w-full flex justify-around">
            <BsSignpostSplit className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'FiSettings':
        return (
          <div className="w-full flex justify-around">
            <FiSettings className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'FiTrash2':
        return (
          <div className="w-full flex justify-around">
            <FiTrash2 className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'FiEye':
        return (
          <div className="w-full flex justify-around">
            <FiEye className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BiBed':
        return (
          <div className="w-full flex justify-around">
            <BiBed className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BiPlusMedical':
        return (
          <div className="w-full flex justify-around">
            <BiPlusMedical className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'GiBinoculars':
        return (
          <div className="w-full flex justify-around">
            <GiBinoculars className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'GiForkKnifeSpoon':
        return (
          <div className="w-full flex justify-around">
            <GiForkKnifeSpoon className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'GiNightSleep':
        return (
          <div className="w-full flex justify-around">
            <GiNightSleep className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'GiAirplaneDeparture':
        return (
          <div className="w-full flex justify-around">
            <GiAirplaneDeparture className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'MdPets':
        return (
          <div className="w-full flex justify-around">
            <MdPets className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'MdOutlineWarningAmber':
        return (
          <div className="w-full flex justify-around">
            <MdOutlineWarningAmber className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      case 'BsClock':
        return (
          <div className="w-full flex justify-around">
            <BsClock className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
          </div>
        )
      default:
        break;
    }
  }

  //open subcategory
  const openSubCategory = (e) => {
    const button = e.currentTarget.children[2];
    const Section = e.currentTarget.parentNode;
    if(button.classList.contains('rotateButtonDown')){
      button.classList.add('rotateButtonUp')
      button.classList.remove('rotateButtonDown')
    } else {
      button.classList.add('rotateButtonDown')
      button.classList.remove('rotateButtonUp')
    }
    if(Section.classList.contains('subCategoryClosed')){
      Section.classList.add('subCategoryOpen')
      Section.classList.remove('subCategoryClosed')
    } else {
      Section.classList.add('subCategoryClosed')
      Section.classList.remove('subCategoryOpen')
    }
  }

  const userId = state?.user?._id;
  const guideId = state?.guide?._id;
  const guideName = state?.guide?.name;
  const ownerEmail = state?.user?.email;
  const guidePhone = state?.guide?.phone

  const mailtoEmail = `mailto:${ownerEmail}`;
  const guideSMS = `sms:${guidePhone}`


  return(
    <div className="bg-gray-200 min-h-screen guide w-full  m-auto ">
      {/* overflow-y-hidden */}
          
    
      {(inCategory === false && inLocations === false) && (
        <>
        <div className='text-white w-full md:w-[900px] m-auto sticky top-0 p-2 flex py-2 md:rounded-b-lg' style={{ backgroundColor: iconColor }}>
            <div className='w-10 h-10 m-1 rounded-full border-2 border-white'>
              <img src={state?.guide?.photo} className='w-full h-full rounded-full' alt='logo'/>
            </div>
            <div className="text-3xl my-auto flex w-1/5 justify-between pl-1">
              <a href={mailtoEmail}><IoMdPaperPlane /></a>
              <a href={guideSMS}><BsTelephone /></a>  
            </div>
            <div className="ml-auto mt-2">
              <SharePage guideName={guideName} userId={userId} guideId={guideId} iconColor={iconColor} navColor={navColor}/>
            </div>
            
          </div>
        <section className="md:flex md:justify-center">
        <div className="flex flex-wrap m-1 rounded-lg pb-20 m-auto md:w-[414px] md:px-5 ml-1 md:max-h-[84vh] md:overflow-y-scroll">
        {/* [...unOrderedCategories].sort((a, b) => a.order - b.order).map((category, index) => { */}
          {[...state?.guide?.categories].sort((a, b) => a.order - b.order).map((category) => {
            return(
              <button key={category._id} onClick={() => showCategory(category)} className="bg-white border-2 border-gray-100 w-[47%] h-[200px] md:w-full lg:h-fit  grid place-items-center rounded-md guideShadow m-1 m-auto md:flex">
                <div className='w-full text-xl md:flex'>
                  <div className="w-full md:w-1/4">{displayIcon(category.icon)}</div>
                  <h3 className="mt-2 md:mt-6 md:ml-auto capitalize font-semibold md:w-4/5 md:text-2xl md:text-right md:mr-4">{category.name}</h3>
                </div>
              </button>
            )
          })}
          {state?.guide?.poi.length > 1 && (
            <button onClick={showLocations} className="bg-white border-2 border-gray-100 w-[47%] h-[200px] md:w-full lg:h-fit grid place-items-center rounded-md guideShadow my-1 m-auto">
              <div className='w-full text-xl md:flex'>
                <div className="w-full md:w-1/4 flex justify-around">
                  <HiOutlineLocationMarker className="text-7xl md:mr-auto md:my-2 md:ml-4 md:pr-5 md:border-r-2" style={{ color: iconColor }}/> 
                </div>
                <h3 className="mt-2 md:mt-6 md:ml-auto capitalize font-semibold md:w-4/5 md:text-2xl md:text-right md:mr-4">Locations</h3>
              </div>
            </button>
          )}
        </div>
        <div className="hidden md:inline-block w-[414px] bg-white guideShadow mt-3 rounded-lg border-2 border-gray-100 p-5 h-fit md:max-h-[84vh] md:overflow-y-scroll">
            <h2 className="font-bold text-xl border-b-2">{activeCategory?.name}</h2>
            <div id="subCatdescription" className="text-left bg-white rounded-md" dangerouslySetInnerHTML={{__html: activeCategory?.description}} />
            {/* <p className="py-2">{activeCategory?.description}</p> */}
            {activeCategory?.subCategories.map((subCategory) => {
              return ( 
                <div key={subCategory._id} className='mb-3 subCategoryClosed bg-white rounded subCategory'>
                  <header onClick={openSubCategory} className="flex justify-between bg-white p-2 pt-3 cursor-pointer">
                    <IoIosArrowDown className="opacity-0" />
                    <h1 className="font-bold">{subCategory.name}</h1>
                    <button className="rotateButtonDown">
                      <IoIosArrowDown className="text-xl text-black" />
                    </button>
                  </header>
                  
                  <article className="">
                  {subCategory.picture && (
                      <img className="w-full h-[11rem] object-cover mb-3 rounded" src={subCategory.picture} />
                  )}
                  <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: subCategory.description}} />
                    {/* <p className="text-left mx-2 text-[18px]">{subCategory.description}</p> */}
                    {subCategory.link && (
                      <a href={subCategory.link} target='_blank' className='text-blue-500 underline text-[18px] mt-1'>
                        {subCategory.linkName ? 
                        (
                          <p>{subCategory.linkName}</p>
                        ) : (
                          <p>{subCategory.link}</p>
                        )}
                      </a>
                    )}
                  </article>
                </div>
              )
            })}
        </div>
        </section>
        </>
      )}
      {inCategory && (
        <Category props={activeCategory} setInCategory={setInCategory} iconColor={iconColor}/>
      )}
      {inLocations && (
        <Locations setInLocations={setInLocations} />
      )}
    </div>
  )
}

export default GuideInfo;