import React, { useState, useEffect } from "react";
import { TbArrowBigLeft } from 'react-icons/tb';
import { IoIosArrowDown } from 'react-icons/io';
import { useStoreContext } from "../../../utils/GlobalState";
import Select from "react-select";

const Locations = (props) => {
  const [state] = useStoreContext();
  const setInLocations = props.setInLocations;
  const guideLocations = state?.guide?.poi;
  const color = state?.guide?.colorTwo || 'gray'
  console.log(color)
  console.log(guideLocations)
  window.scrollTo(0, 0);

  const openSubCategory = (e) => {
    // console.log(e.currentTarget.children[2], e.currentTarget.parentNode)
    const button = e.currentTarget.children[2];
    const Section = e.currentTarget.parentNode;
    if(button.classList.contains('rotateButtonDown')){
      button.classList.add('rotateButtonUp')
      button.classList.remove('rotateButtonDown')
    } else {
      button.classList.add('rotateButtonDown')
      button.classList.remove('rotateButtonUp')
    }
    if(Section.classList.contains('subCategoryClosed')){
      Section.classList.add('subCategoryOpen')
      Section.classList.remove('subCategoryClosed')
    } else {
      Section.classList.add('subCategoryClosed')
      Section.classList.remove('subCategoryOpen')
    }
  }

  //FILTERS
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    // guideLocations.forEach(location => {
    //   if(location.type){
    //     filterOptions.push(location.type)
    //     setFilterOptions([
    //       ...filterOptions,
    //       location.type
    //     ]);
    //   }
    // });
    const key = 'type'
    const types = guideLocations.reduce((acc, obj) => {
      const value = obj[key];
      if (!acc.set.has(value)) {
        acc.set.add(value);
        acc.values.push(value);
      }
      return acc;
    }, {set: new Set(), values: []}).values;
    console.log(types)

    types.forEach(type => {
      switch(type){
        case "Bar":
            filterOptions.push({value: type,
            label:<p>Bars</p> });
          break;
        case "Beach":
            filterOptions.push({value: type,
            label:<p>Beaches</p> });
          break;
        case "Bike":
            filterOptions.push({value: type,
            label:<p>Bike Trails</p> });
          break;
        case "Boat":
            filterOptions.push({value: type,
            label:<p>Boating/Watersports</p> });
          break;
        case "Food":
            filterOptions.push({value: type,
            label:<p>Restaurants</p> });
          break;
        case "Shopping":
            filterOptions.push({value: type,
            label:<p>Shopping</p> });
          break;
        case "Park":
            filterOptions.push({value: type,
            label:<p>Parks</p> });
          break;
        case "Coffee":
            filterOptions.push({value: type,
            label:<p>Coffee</p> });
          break;
        case "Forest":
            filterOptions.push({value: type,
            label:<p>Nature</p> });
          break;
        case "Gasoline":
            filterOptions.push({value: type,
            label:<p>Gasoline</p> });
          break;
        case "Golf":
            filterOptions.push({value: type,
            label:<p>Golf</p> });
          break;
        case "Groceries":
            filterOptions.push({value: type,
            label:<p>Groceries</p> });
          break;
        case "Entertainment":
            filterOptions.push({value: type,
            label:<p>Entertainment</p> });
          break;
        case "Swim":
            filterOptions.push({value: type,
            label:<p>Swimming</p> });
          break;
        case "Camera":
            filterOptions.push({value: type,
            label:<p>Sight Seeing</p> });
          break;
        case "Trail":
            filterOptions.push({value: type,
            label:<p>Hikes/trails</p> });
          break;
        case "Medical":
            filterOptions.push({value: type,
            label:<p>Medical locations</p> });
          break;
          case "Worship":
            filterOptions.push({value: type,
            label:<p>Churches</p> });
          break;
      }
      
    });
  }, []);

  const [filterOpen, setFilterOpen] = useState(false)
  const [displayedLocations, setDisplayedLocations] = useState('all')
  const toggleFilter = () =>{
    filterOpen ? setFilterOpen(false) : setFilterOpen(true);
  }
  const showAll = () => {
    setDisplayedLocations('all')
    setFilterOpen(false)
  }
  const [oneOpen, setOneOpen] = useState(false)
  const openPickOne = () => {
    oneOpen ? setOneOpen(false) : setOneOpen(true)
  }
  const showOne = (type) => {
    setDisplayedLocations(type)
  }

  const pickOption = (e) => {
    console.log(e)
    toggleFilter();
    setDisplayedLocations(e.value)
  }

  console.log(filterOptions)
  return (
    <div className="w-full font md:max-h-[87vh] md:overflow-y-scroll">
      <nav className="w-full flex justify-between px-2 pt-4 py-3 text-white font-bold" style={{ backgroundColor: color}}>
        <button onClick={() => setInLocations(false)} className='text-3xl'><TbArrowBigLeft /></button>
        <h2 className="text-2xl">Locations</h2>
        <button className="opacity-0 text-3xl"><TbArrowBigLeft /></button>
      </nav>
      <div className="w-full flex">
      <button onClick={showAll} className='shadow bg-white w-2/5 mt-2 border-2 py-2 font-bold mr-auto ml-8 rounded'>See All</button>
        <button onClick={(toggleFilter)} className='shadow bg-white w-2/5 mt-2 border-2 py-2 font-bold ml-auto mr-8 rounded'>Filter Locations</button>
      </div>
      
      <div>
      {guideLocations.length > 0 && 
        <div className="w-11/12 m-auto mt-4">
          {displayedLocations === 'all' ? (
            <>
              {guideLocations.map((guideLocation) => {
                if(guideLocation.name !== state?.guide?.name){
                return ( 
                  <div key={guideLocation._id} className='mb-3 subCategoryClosed bg-white rounded subCategory'>
                    <header onClick={openSubCategory} className="flex justify-between bg-white p-2 pt-3">
                      <IoIosArrowDown className="opacity-0" />
                      <h1 className="border-b-2 font-bold">{guideLocation.name}</h1>
                      <button className="rotateButtonDown relative z-50">
                        <IoIosArrowDown className="text-xl text-black" />
                      </button>
                    </header>
                    <article className="">
                    <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: guideLocation.description}} />
                      {/* <p>{guideLocation.description}</p> */}
                      {guideLocation.address !== null ?(
                        <a className='text-blue-500 font-bold underline my-2' href={'https://maps.google.com/?q=' + guideLocation.address} target='_blank'>Get Directions</a>
                      ) : (
                        <a className='text-blue-500 font-bold underline my-2' href={'https://maps.google.com/?q=' + guideLocation.lat + ',' + guideLocation.lng} target='_blank'>Get Directions</a>
                      )}
                      {guideLocation?.pic && (
                        <img className="w-full h-[220px] object-cover rounded" src={guideLocation.pic} />
                      )}
                      
                    </article>
                  </div>
                )
                }
              })}
            </>
          ) : (
            <>
            {guideLocations.map((guideLocation) => {
                if(guideLocation.type === displayedLocations){
                return ( 
                  <div key={guideLocation._id} className='mb-3 subCategoryClosed bg-white rounded subCategory'>
                    <header onClick={openSubCategory} className="flex justify-between bg-white p-2 pt-3">
                      <IoIosArrowDown className="opacity-0" />
                      <h1 className="border-b-2 font-bold">{guideLocation.name}</h1>
                      <button  className="rotateButtonDown relative z-50">
                        <IoIosArrowDown className="text-xl text-black" />
                      </button>
                    </header>
                    <article className="">
                    <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: guideLocation.description}} />
                      {/* <p>{guideLocation.description}</p> */}
                      {guideLocation.address !== null ?(
                        <a className='text-blue-500 font-bold underline my-2' href={'https://maps.google.com/?q=' + guideLocation.address} target='_blank'>Get Directions</a>
                      ) : (
                        <a className='text-blue-500 font-bold underline my-2' href={'https://maps.google.com/?q=' + guideLocation.lat + ',' + guideLocation.lng} target='_blank'>Get Directions</a>
                      )}
                      {guideLocation?.pic && (
                        <img className="w-full h-[220px] object-cover rounded" src={guideLocation.pic} />
                      )}
                      
                    </article>
                  </div>
                )
                }
              })}
            </>
          )}
        </div>
      }
      </div>
      {filterOpen && (
        <div className="absolute z-90">
        <div className="w-screen h-screen fixed top-0 bg-gray-600 opacity-50"></div>
        <div className="fixed top-20 w-screen">
          <div className="mr-auto ml-auto bg-white w-3/4 rounded-lg ">
            <header className="flex justify-between w-full bg-gray-100 font-bold text-xl pt-3 px-3 border-b-2 rounded-t-lg">
              <h1>Filters</h1>
              <button onClick={(toggleFilter)} className='pr-3'>X</button>
            </header>
            <div className="py-5">
              <p className="text-left w-11/12 ml-auto mr-auto font-bold">By Category:</p>
              <Select 
                className="w-11/12 m-auto"
                options={filterOptions}
                onChange={pickOption}
              />
            </div>
          </div>
        </div>
        </div>
      )}
    </div>
  )
}

export default Locations;