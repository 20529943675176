import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Nav from '../components/home/nav';
import Footer from '../components/footer/index';
import logo from '../assets/icons/light512.png'

function Home() {

return (
  <>

    <Nav />
    <div className='relative z-10 bg-white font'>
      <Outlet/>
    </div>
    
    <Footer/>
  </>
)
};

export default Home;