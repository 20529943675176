import React, { useState } from "react";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useStoreContext } from "../../../utils/GlobalState";
import loadingSvg from '../../../assets/loadGif.gif';


import Home from '../../../assets/mappityMarkers/Home_6.png';
import Star from '../../../assets/mapIcons/Star.png'
import Bar from '../../../assets/mapIcons/Bar.png';
import Beach from '../../../assets/mapIcons/Beach.png';
import Bike from '../../../assets/mapIcons/bike.png';
import Boat from '../../../assets/mapIcons/Boat.png';
import Camera from '../../../assets/mapIcons/Camera.png';
import Coffee from '../../../assets/mapIcons/Coffee.png';
import Entertainment from '../../../assets/mapIcons/Entertainment.png';
import Forest from '../../../assets/mapIcons/Forest.png';
import Gasoline from '../../../assets/mapIcons/Gasoline.png';
import Golf from '../../../assets/mapIcons/Golf.png';
import Groceries from '../../../assets/mapIcons/Groceries.png';
import Hike from '../../../assets/mapIcons/Hike.png';
import Medical from '../../../assets/mapIcons/Medical.png';
import Park from '../../../assets/mapIcons/Park.png';
import Restaurant from '../../../assets/mapIcons/Restaurant.png';
import Shopping from '../../../assets/mapIcons/Shopping.png';
import Swimming from '../../../assets/mapIcons/Swimming.png';
import Worship from '../../../assets/mapIcons/Worship.png';


function GuideMap() {
  const [state] = useStoreContext();
  const places = state?.guide?.poi;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDDkRTAj5v0klTMIXQeDdG8HcT0RbdwrOc',
  })

  const [map, setMap] = useState( /** @type google.maps.map */ (null));

  const home = state?.guide?.poi?.find(({ name }) => name === state?.guide?.name);

  const [selectedLocation, setSelectedLocation] = useState(null);

  if(!isLoaded) {
    return (
      <div className="h-screen w-screen pt-48 bg-white">
        <p className="mt-auto guide text-2xl">Map is Loading</p>
        <img className="m-auto" alt="loading" src={loadingSvg} />
      </div>
    )
    
  }

  const defaultCenter = {
    lat: parseFloat(state?.guide?.poi[0]?.lat) || 10, lng: parseFloat(state?.guide?.poi[0]?.lng) || 10
  }

  const showIcon = (type) => {
    switch(type) {
      case 'Bar':
        return Bar;
      case 'Beach':
        return Beach;
      case 'Bike':
        return Bike;
      case 'Boat':
        return Boat;
      case 'Camera':
        return Camera;
      case 'Coffee':
        return Coffee;
      case 'Entertainment':
        return Entertainment;
      case 'Forest':
        return Forest;
      case 'Food':
        return Restaurant;
      case 'Gasoline':
        return Gasoline;
      case 'Golf':
        return Golf;
      case 'Groceries':
        return Groceries;
      case 'Trail':
        return Hike;
      case 'Medical':
        return Medical;
      case 'Park':
        return Park;
      case 'Shopping':
        return Shopping;
      case 'Swim':
        return Swimming;
      case 'Worship':
        return Worship;
    }
  }
  
  return(
    <div className="guideMap w-screen">
    <div className="w-full h-full">
    <GoogleMap center={defaultCenter} zoom={13} mapContainerStyle={{width: '100%', height: '100%'}}
    onLoad={(map) => setMap(map)}
    options={{
      fullscreenControl: false,
      zoomControlOptions: { position: 3 },
      gestureHandling: "greedy",
    }}
    >
      {places.map((place) => {
        if(place.name === home.name) {
          return(
            <Marker key={place._id} position={place} 
              onClick={() => {
                setSelectedLocation(place); 
              }}
              icon={{
                url: Star,
                scaledSize: new window.google.maps.Size(35,41)
              }}
            />
          )
        }
        return (
          <Marker key={place._id} position={place}
          onClick={() => {
            setSelectedLocation(place); 
          }}
          icon={{
            url: showIcon(place.type),
            scaledSize: new window.google.maps.Size(35,42)
          }}
          />
        )
      })}

      {selectedLocation && (
        <InfoWindow position={selectedLocation}
        onCloseClick={() => {
          setSelectedLocation(null);
        }}>
          <div className='px-2 pb-2 text-lg guide'>
          <h1 className='font-bold text-xl pt-1 pb-2 mr-auto text-left w-fit'>{selectedLocation.name}</h1>
          <div className="mr-auto text-left w-fit">
            
          <div id="subCatdescription" className="text-left" dangerouslySetInnerHTML={{__html: selectedLocation.description}} />
          {/* <p className='pt-1 text-left mb-1'>{selectedLocation.description}</p> */}
          {selectedLocation.address !== null ?(
            <a className='text-blue-500 font-bold  border-b-2 border-blue-500 my-2' href={'https://maps.google.com/?q=' + selectedLocation.address} target='_blank'>Get Directions</a>
          ) : (
            <a className='text-blue-500 font-bold  border-b-2 border-blue-500 my-2' href={'https://maps.google.com/?q=' + selectedLocation.lat + ',' + selectedLocation.lng} target='_blank'>Get Directions</a>
          )}
          
          </div>
          
          
          </div>
        </InfoWindow>
      )}

      {/* <Marker position={defaultCenter}/> */}
      <div className='w-full h-full flex justify-between'>
{/*         
        <button onClick={() => map.panTo(defaultCenter)} className='centerMapButton bg-gray-50 shadow-lg p-2 pr-1 m-3 mt-24 z-50 h-8 grid content-center rounded-sm'><p className='centerMapButtonIcon text-lg '>➤</p></button> */}
        <p></p>
      </div>
      
    </GoogleMap>
      
    </div>
    </div>
  )
}

export default GuideMap;
