import React, { Component } from 'react';
import logo from '../../../assets/icons/dark-png1.png';
import loadingVideo from "../../../assets/vacanzeguides.mp4";
import loadGif from '../../../assets/loadGif.gif'
import curvyLines from '../../../assets/curvyLines.jpg'

function SplashMessage() {
    return (
        <div className='md:w-[400px] w-full flex flex-wrap m-auto h-screen align-center' >
            {/* <img src={curvyLines} className="w-screen  h-screen block m-auto absolute fit-cover opacity-[.10]" alt="logo" /> */}
            <div className='w-full mt-auto mb-0 '>
            <img src={logo} className="md:w-[400px] w-4/5 block m-auto relative" alt="logo" />
            </div>
            <div className='w-full  mb-auto'>
            <img src={loadGif} className=" w-[100px] block m-auto relative" alt="lading" /> 
            </div>
            
            {/* <video src={loadingVideo} autoPlay muted style={{ width: '300px', height: 'auto', display: 'flex', margin: 'auto' }}/> */}
        </div>
    );
}

export default function withSplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                // Put here your await requests/ API requests
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1000)
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return SplashMessage();

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}