import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_GUIDE } from '../../../utils/mutations';
import { useStoreContext } from "../../../utils/GlobalState";

const DeleteGuide = () => {
  const { guideId } = useParams();
  const [state] = useStoreContext();

    //delete guide functionality
    const [deleteActive, setDeleteActive] = useState('false');
    const [deleteGuide] = useMutation(DELETE_GUIDE);
    
    const deleteGuidename = useRef();
  
    const [inputLabelValue, setinputLabelValue] = useState('Enter the name of this guide to delete this guide.');

    const removeClass = () =>{
      deleteGuidename.current.classList.remove('border-red-500');
      deleteGuidename.current.classList.remove('error')
    }

    const handleDeleteGuide = async () => {
      if(deleteGuidename.current.value === state?.guide?.name){
        const updatedUser = await deleteGuide({
          variables: {
            guideId: guideId
          }
        });
        window.location.replace('/dashboard');
      } else {
        console.log('dont delete')
        setinputLabelValue('The incorrect name was entered, guide cannot be deleted until correct name is entered.')
        // deleteGuidename.classList.remove('border-')
        deleteGuidename.current.classList.add('border-red-500')
        deleteGuidename.current.classList.add('error')
      }
    };

  return (
  <>
    <div className="bg-white m-5 rounded-lg shadow">
        <button className="bg-red-600 p-2 m-3 rounded-md text-white font-bold hover:shadow-xl" onClick={() => setDeleteActive('true')}>DELETE GUIDE</button>
      </div>
      {deleteActive === 'true' && (
        <div className="absolute top-0 left-0 w-screen h-screen z-40">
          <div className="w-full h-full bg-gray-700 opacity-60"> </div>
          <div className="bg-white rounded-lg absolute top-1/4 left-1/4 flex flex-wrap w-1/2 m-auto border-2 border-blue-900">
            <h1 className="w-full text-center text-2xl font-bold mt-5 text-red-500 ">WARNING</h1> 
            <p className="m-auto mt-2 text-xl font-bold">Are you sure you want to delete your guide?</p>
            <p className="p-5">This action is permanent and irreversable, if you want to remake this guide you will have to start from scratch.  Do you wish to continue?</p>
            <div className="w-full flex justify-around m-3"> 
              <button onClick={() => setDeleteActive('false')} className="shadow-md bg-gray-200 text-black font-bold rounded-md p-2 px-3 pt-3 mb-3 hover:shadow-xl">CANCEL</button>
              <label className="flex flex-wrap w-1/2 text-sm">
                <p className="text-left pb-2">{inputLabelValue}</p>
                <input ref={deleteGuidename} onChange={removeClass} type='text' className="inpppuuut w-full border-2 rounded-md relative bottom-2 px-3" placeholder="Guide Name"/>
              </label>
              
            <button onClick={() => handleDeleteGuide()} className="shadow-lg bg-red-600 text-white font-bold rounded-md p-2 px-3 pt-3 mb-3 hover:shadow-xl">DELETE GUIDE</button>
            </div>
            
          </div>
          
        </div>
      )}
    </>
  )
};

export default DeleteGuide;