import React, { useState, useEffect } from "react";
import { BsShare } from "react-icons/bs";
import QRCode from "react-qr-code";

const SharePage = (props) => {
  const [activeShare, setActiveShare] = useState('false');
  

  const userId = props.userId;
  const guideId = props.guideId;
  const guideName = props.guideName;


  const url = `${window.location.href}`;

  const [confirmCopy, setConfirmCopy] = useState('false');
  const copy = () => {
    navigator.clipboard.writeText(url);
    setConfirmCopy('true');
  }

  const createEmail = `mailto:?to=&body=Check out our guide to ${guideName}: ${url}&subject=Check out this guide to ${guideName}`;
  // sms:?body=Checkout%20our%20guidebook%20for%20Anini%20Beach%20Bungalow.%0A%0Ahttps%3A%2F%2Fvacanze.herokuapp.com%2Fuser%2F63d9d428f366de6c77a3f86c%2Fguide%2F6429d1e99590aabaa72cadae"
  const createText = `sms:?//&body=Check out this guide to ${guideName}: ${url}`;

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // useEffect(() => {

  //     // CHECK IF USE HAS DOWNLOADED PWA ALREADY
  //     if (!window.matchMedia('(display-mode: standalone)').matches) {  
  //       //  CHECK WHAT TYPE OF PHONE USER IS ON

  //       // Windows Phone must come first because its UA also contains "Android"
  //       if (/windows phone/i.test(userAgent)) {
  //         createText = `sms://&body=Check out this guide to ${guideName}: ${url}`;
  //       }

  //       if (/android/i.test(userAgent)) {
  //         createText = `sms://?body=Check out this guide to ${guideName}: ${url}`;
  //       }

  //       // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //         createText = `sms://;body=Check out this guide to ${guideName}: ${url}`;
  //       }

  //     }  

    
  //     return;
  // }, []);

  const [qrState, setQrState] = useState(false);

  const showQRCode = () => {
    if(qrState) {
      setQrState(false)
    } else {
      setQrState(true)
    }
    
  }

  return (
    <>
      {activeShare === 'false' ? (
      <button onClick={() => setActiveShare('true')}>
        <BsShare className="text-3xl my-auto ml-auto mr-2" />
      </button>
      ) : (
      <div className="absolute top-0 bg-white w-screen h-screen right-0 text-black font  md:w-[900px]">
        <div className='w-full text-2xl font-bold text-white py-4 text-shadow flex justify-between' style={{ backgroundColor: props.iconColor }}>
        <button className="font-bold pl-4 opacity-0">X</button> 
        <h2>Share Guide Via... </h2>
        <button className="font-bold pr-4 text-shadow"  onClick={() => setActiveShare('false')}>X</button>
        </div>
        {/* <section className="flex justify-between w-5/6 pt-12 m-auto text-2xl">
          <p className="text-white">x</p>
          <h2 className="w-1/2 text-center">Share via</h2>
          <button className="font-bold" onClick={() => setActiveShare('false')}>X</button>
        </section> */}
        <nav className="flex flex-wrap w-4/5 m-auto pt-12">
          <button onClick={copy} className="w-full p-2 text-xl font-bold m-3 mt-6 linkShadow rounded-md" style={{border: `.5px solid ${props.navColor}`}}>Copy Link</button>
          <a href={createEmail} className="w-full p-2 text-xl font-bold m-3 linkShadow rounded-md" style={{border: `.5px solid ${props.navColor}`}}>Email</a>
          <a href={createText} className="w-full p-2 text-xl font-bold m-3 linkShadow rounded-md" style={{border: `.5px solid ${props.navColor}`}}>Text Message</a>
          <button onClick={showQRCode} className="w-full p-2 text-xl font-bold m-3 linkShadow rounded-md" style={{border: `.5px solid ${props.navColor}`}}>See QR Code</button>
        </nav>
        {qrState && (
        <div className="absolute top-0 w-screen h-screen">
          <div className="absolute z-0 w-screen md:w-[900px] h-screen bg-gray-600 opacity-30"></div>
          <div className="relative w-4/5 md:w-[450px] md:right-[25%] mt-36 z-10 m-auto">
            <div className="bg-white mx-8 pb-8 rounded-md">
              <header className="flex justify-between px-4 bg-gray-100 text-2xl rounded-t-md mb-12">

                <h4 className="text-black font-bold py-4">QR Code</h4>
                <button onClick={showQRCode} className="text-black font-bold">X</button>
              </header>
              
              <QRCode id="qrCode" className="m-auto" value={url} />
            </div>
          </div>
        </div>
        )}
        
        <div id='copied' className={confirmCopy === 'false' ? ("opacity-0 bg-gray-50 absolute left-8 top-96 z-0 w-0 p-3 z-50 rounded flex justify-around duration-500"
        ) : (
          "opacity-100 h-12 bg-gray-50 absolute left-8 top-10 z-10 w-5/6 p-3 z-50 rounded text-xl font-bold flex justify-around duration-500"
        )}>
          <p className="text-gray-50">x</p> 
          <p>✔️ Copied to Clipboard!</p>
          <button onClick={() => setConfirmCopy('false')}>X</button>
        </div>
      </div>
      )}

    
  </>
  )
}

export default SharePage;




