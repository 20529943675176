import React from "react";
import { MdOutlineIosShare } from 'react-icons/md';

const IOS = (props) => {
  const setIsMobile = props.setIsMobile;

  return (
    <div className="absolute bottom-0 w-full noMargin">
      <button onClick={() => setIsMobile(false)} className="relative bigZ left-36 top-4 w-8 h-8 pt-1 bg-white rounded-md font-bold guideShadow text-center text-xl">X</button>
      <div className="speech-bubble w-4/5 p-3 m-auto flex flex-wrap">
        <p className="inline">
          To add a shortcut to your home screen, hit the <MdOutlineIosShare className="text-xl inline"/> icon and tap "Add to Home Screen".
        </p>
        <p>
          Option not showing for you? Copy this link and paste it into Safari and try again.
        </p>
      </div>
    </div>
  )
}

export default IOS;